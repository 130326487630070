import "./dataSheet.scss";
import PropertyEventShared from './propertyEventShared';
import { ReportSaleEventModel } from 'dto/reports/reportSaleEvent';
import { Col, Row } from 'reactstrap';
import { applySalesConfidential, isModelVisible, setSaleEventModelVisibility, setSaleOperationsVisibility } from 'components/utils/reports/reportVisibility';
import PropertyAndEventComments from './propertyEventComments';
import { formatNumberNoDecimalsWithCurrency, formatNumberWithDecimals, formatNumberWithDecimalsWithCurrency, formatNumberWithDecimalsWithPercentage, formatNumberWithNoDecimals, formatYesNo } from 'components/utils/numberFormatting/numberFormat';
import { dateFormatter } from "components/utils/columnFormatting/date";

const Sale = ({ data }: { data: ReportSaleEventModel }) => {
    let saleEventDataModel = data as ReportSaleEventModel;
    let saleEvent: ReportSaleEventModel;
    let saleEventModel = setSaleEventModelVisibility(saleEventDataModel);
    if (saleEventModel?.reportPropertyEventModel?.isConfidential) {
        saleEvent = applySalesConfidential(saleEventModel);
    }
    else
        saleEvent = saleEventModel;
    let reportPropertySaleEvent = saleEvent.reportPropertySaleEventModel;
    let isPropertySaleModelVisible: boolean = isModelVisible(reportPropertySaleEvent);
    let reportPropertyEventModel = saleEvent.reportPropertyEventModel;
    let saleOperationModel = saleEvent.reportPropertySaleOperationsAtDateOfSaleEventModel;
    let saleOpsModel = setSaleOperationsVisibility(saleOperationModel);
    let isPropertySaleOpsModelVisible: boolean = isModelVisible(saleOpsModel);
    const currencyTypeCode: string = reportPropertyEventModel ? reportPropertyEventModel.currencyTypeCode : 'USD';

    function getSaleTitle(): string {
        let propertyType = reportPropertyEventModel?.propertyTypeDescription || '';
        let propertyEventType = reportPropertyEventModel?.propertyEventTypeDescription || '';
        let propertyTitle = propertyType + ' ' + propertyEventType;
        let saleStatus = reportPropertySaleEvent?.saleStatusTypeDescription || '';

        if (reportPropertySaleEvent) {
            const statusId = reportPropertySaleEvent.saleStatusTypeId;
            if (statusId === 3 || statusId === 4) {
                saleStatus = '';
            }
        }

        if (!saleStatus) {
            return propertyTitle;
        }

        return `${propertyTitle} (${saleStatus})`;
    };

    function HasFinancialData(): boolean {
        if (saleOpsModel?.actualCapRateDerivedPercent) {
            return true;
        }

        if (saleOpsModel?.actualEGIMDerived) {
            return true;
        }
        if (saleOpsModel?.capRateDerivedStabilized) {
            return true;
        }
        if (saleOpsModel?.derivedEGIM) {
            return true;
        }
        if (saleOpsModel?.reportedDiscountRate) {
            return true;
        }
        if (saleOpsModel?.reportedReversionCapRate) {
            return true;
        }
        return false;
    }
    function shouldDisplayPGIRow(): boolean {
        if (saleOpsModel === null)
            return false;

        return (
            saleOpsModel?.isVisibleActualPGI
            || saleOpsModel?.isVisibleActualPGIPerSmallUOM
            || saleOpsModel?.isVisibleActualPGIPerUnit
            || saleOpsModel?.isVisibleProFormaStabPGI
            || saleOpsModel?.isVisibleProFormaStabPGIPerUOM
            || saleOpsModel?.isVisibleProFormaStabPGIPerUnit);
    }

    function shouldDisplayOtherIncomeRow(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualOtherIncome ||
            saleOpsModel?.isVisibleActualOtherIncomePerSmallUOM ||
            saleOpsModel?.isVisibleActualOtherIncomePerUnit ||
            saleOpsModel?.isVisibleProFormaStabOtherIncome ||
            saleOpsModel?.isVisibleProFormaStabOtherIncomePerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabOtherIncomePerUnit;
    }
    function shouldDisplayEgiRow(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualEGI ||
            saleOpsModel?.isVisibleActualEGIPerSmallUOM ||
            saleOpsModel?.isVisibleActualEGIPerUnit ||
            saleOpsModel?.isVisibleProFormaStabEGI ||
            saleOpsModel?.isVisibleProFormaStabEGIPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabEGIPerUnit;
    }

    function shouldDisplayOpExpenseRow(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualOperatingExpense ||
            saleOpsModel?.isVisibleActualOperatingExpensePerSmallUOM ||
            saleOpsModel?.isVisibleActualOperatingExpensePerUnit ||
            saleOpsModel?.isVisibleProFormaStabOPExp ||
            saleOpsModel?.isVisibleProFormaStabOPExpPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabOPExpPerUnit;
    }

    function shouldDisplayNOIRow(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualNOI ||
            saleOpsModel?.isVisibleActualNOIPerSmallUOM ||
            saleOpsModel?.isVisibleActualNOIPerUnit ||
            saleOpsModel?.isVisibleProFormaStabNOI ||
            saleOpsModel?.isVisibleProFormaStabNOIPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabNOIPerUnit;
    }

    function shouldDisplayOperationsAtDateOfSaleSection(): boolean {
        return shouldDisplayPGIRow() ||
            shouldDisplayOtherIncomeRow() ||
            shouldDisplayEgiRow() ||
            shouldDisplayOpExpenseRow() ||
            shouldDisplayNOIRow();
    }

    function shouldDisplayFinancialDataSection(): boolean {
        if (saleOpsModel === null)
            return false;

        return HasFinancialData() &&
            (saleOpsModel?.isVisibleActualOperatingExpenseRationPercent ||
                saleOpsModel?.isVisibleProFormaStabOPExpRatio ||
                saleOpsModel?.isVisibleActualCapRateDerivedPercent ||
                saleOpsModel?.isVisibleActualEGIMDerived ||
                saleOpsModel?.isVisibleCapRateDerivedStabilized ||
                saleOpsModel?.isVisibleDerivedEGIM ||
                saleOpsModel?.isVisibleReportedDiscountRate ||
                saleOpsModel?.isVisibleReportedReversionCapRate);
    }

    function shouldDisplayActualsPerSFColumnHeader(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualPGIPerSmallUOM ||
            saleOpsModel?.isVisibleActualOtherIncomePerSmallUOM ||
            saleOpsModel?.isVisibleActualEGIPerSmallUOM ||
            saleOpsModel?.isVisibleActualOperatingExpensePerSmallUOM ||
            saleOpsModel?.isVisibleActualNOIPerSmallUOM;
    }

    function shouldDisplayActualsPerUnitColumnHeader(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualPGIPerUnit ||
            saleOpsModel?.isVisibleActualOtherIncomePerUnit ||
            saleOpsModel?.isVisibleActualEGIPerUnit ||
            saleOpsModel?.isVisibleActualOperatingExpensePerUnit ||
            saleOpsModel?.isVisibleActualNOIPerUnit;
    }

    function shouldDisplayProFormaPerSFColumnHeader(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleProFormaStabPGIPerUOM ||
            saleOpsModel?.isVisibleProFormaStabOtherIncomePerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabEGIPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabOPExpPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabNOIPerSmallUOM;
    }

    function shouldDisplayProFormaPerUnitColumnHeader(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel.isVisibleProFormaStabPGIPerUnit ||
            saleOpsModel?.isVisibleProFormaStabOtherIncomePerUnit ||
            saleOpsModel?.isVisibleProFormaStabEGIPerUnit ||
            saleOpsModel?.isVisibleProFormaStabOPExpPerUnit ||
            saleOpsModel?.isVisibleProFormaStabNOIPerUnit;
    }

    function shouldDisplayActualsPGIRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualPGI ||
            saleOpsModel?.isVisibleActualPGIPerSmallUOM ||
            saleOpsModel?.isVisibleActualPGIPerUnit;
    }

    function shouldDisplayProFormaPGIRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleProFormaStabPGI ||
            saleOpsModel?.isVisibleProFormaStabPGIPerUOM ||
            saleOpsModel?.isVisibleProFormaStabPGIPerUnit;
    }

    function shouldDisplayActualsOtherIncomeRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualOtherIncome ||
            saleOpsModel?.isVisibleActualOtherIncomePerSmallUOM ||
            saleOpsModel?.isVisibleActualOtherIncomePerUnit;
    }

    function shouldDisplayProFormaOtherIncomeRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleProFormaStabOtherIncome ||
            saleOpsModel?.isVisibleProFormaStabOtherIncomePerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabOtherIncomePerUnit;
    }

    function shouldDisplayActualsEGIRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualEGI ||
            saleOpsModel?.isVisibleActualEGIPerSmallUOM ||
            saleOpsModel?.isVisibleActualEGIPerUnit;
    }

    function shouldDisplayProFormaEGIRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleProFormaStabEGI ||
            saleOpsModel?.isVisibleProFormaStabEGIPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabEGIPerUnit;
    }

    function shouldDisplayActualsOperatingExpenseRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualOperatingExpense ||
            saleOpsModel?.isVisibleActualOperatingExpensePerSmallUOM ||
            saleOpsModel?.isVisibleActualOperatingExpensePerUnit;
    }

    function shouldDisplayProFormaOPExpRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleProFormaStabOPExp ||
            saleOpsModel?.isVisibleProFormaStabOPExpPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabOPExpPerUnit;
    }

    function shouldDisplayActualsNOIRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleActualNOI ||
            saleOpsModel?.isVisibleActualNOIPerSmallUOM ||
            saleOpsModel?.isVisibleActualNOIPerUnit;
    }

    function shouldDisplayProFormaNOIRowLabel(): boolean {
        if (saleOpsModel === null)
            return false;

        return saleOpsModel?.isVisibleProFormaStabNOI ||
            saleOpsModel?.isVisibleProFormaStabNOIPerSmallUOM ||
            saleOpsModel?.isVisibleProFormaStabNOIPerUnit;
    }

    function shouldDisplayProFormaSectionHeader(): boolean {
        return shouldDisplayProFormaPGIRowLabel() ||
            shouldDisplayProFormaOtherIncomeRowLabel() ||
            shouldDisplayProFormaEGIRowLabel() ||
            shouldDisplayProFormaOPExpRowLabel() ||
            shouldDisplayProFormaNOIRowLabel();
    }

    function shouldDisplayActualsSectionHeader(): boolean {
        return shouldDisplayActualsPGIRowLabel() ||
            shouldDisplayActualsOtherIncomeRowLabel() ||
            shouldDisplayActualsEGIRowLabel() ||
            shouldDisplayActualsOperatingExpenseRowLabel() ||
            shouldDisplayActualsNOIRowLabel();
    }

    const renderSaleInformation = () => {
        return (<>
            <Row>
                <Col className="p-0" md={6} sm={6} xs={6}>
                    <div className="p-1 mt-3 heading-bg-primary">
                        <h5 className="title ps-2 p-1 mb-1 fw-bold">Sale Information</h5>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={6} xs={6}>
                    {reportPropertySaleEvent?.isVisibleSpecialtySaleTypeDescription &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Specialty Sale Type</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.specialtySaleTypeDescription}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleTransactionTypeDescription &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Transaction Type</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.transactionTypeDescription}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleListingDate &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Listing Date</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {dateFormatter(reportPropertySaleEvent?.listingDate)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleListingPrice &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Listing Price</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberNoDecimalsWithCurrency(reportPropertySaleEvent?.listingPrice, currencyTypeCode)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleSaleStatusTypeDescription &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Sale Status</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.saleStatusTypeDescription}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleSaleDate &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Sale Date</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {dateFormatter(reportPropertySaleEvent?.saleDate)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleGrantor &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Grantor (Seller)</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.grantor}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleGrantee &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Grantee (Buyer)</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.grantee}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleContractDate &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Contract Date</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {dateFormatter(reportPropertySaleEvent?.contractDate)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleRecordingDate &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Recording Date</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {dateFormatter(reportPropertySaleEvent?.recordingDate)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleDocumentType &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Document Type</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.documentType}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleRecordingNumber &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Recording Number</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.recordingNumber}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisiblePropertyRightsTypeDescription &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Property Rights</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.propertyRightsTypeDescription}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleFinancingTypeDescription &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Financing Type</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {reportPropertySaleEvent?.financingTypeDescription}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleSalePrice &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Sale Price</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberNoDecimalsWithCurrency(reportPropertySaleEvent?.salePrice, currencyTypeCode)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleFinancingTermsAdjustment &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Financing Terms Adjustment</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberNoDecimalsWithCurrency(reportPropertySaleEvent?.financingTermsAdjustment, currencyTypeCode)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleConditionsOfSaleAdjustment &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Conditions Of Sale Adjustment</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberNoDecimalsWithCurrency(reportPropertySaleEvent?.conditionsOfSaleAdjustment, currencyTypeCode)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleOtherAdjustment &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Other Adjustment</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberNoDecimalsWithCurrency(reportPropertySaleEvent?.otherAdjustment, currencyTypeCode)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleEffectiveSalePrice &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Effective Sales Price</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.effectiveSalePrice, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisiblePricePerSmallUOMGBA &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per SF GBA</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.pricePerSmallUOMGBA, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisiblePricePerSmallUOMNRA &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per SF NRA</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.pricePerSmallUOMNRA, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleEffectivePricePerUnit &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Effective Price Per Unit</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.effectivePricePerUnit, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisiblePotentialValueFAR &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per Potential FAR</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.potentialValueFAR, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleGrossValueLandPerSmallUOM &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per Land SF (Gross)</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.grossValueLandPerSmallUOM, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleGrossValueLandPerLargeUOM &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per Acre (Gross)</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.grossValueLandPerLargeUOM, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleUsableValueLandPerSmallUOM &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per Land SF (Usable)</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.usableValueLandPerSmallUOM, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                    {reportPropertySaleEvent?.isVisibleUsableValueLandPerLargeUOM &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Price Per Acre (Usable)</label>
                            </Col>
                            <Col xs={6}>
                                <label className='fw-normal'>
                                    {formatNumberWithDecimalsWithCurrency(reportPropertySaleEvent?.usableValueLandPerLargeUOM, currencyTypeCode, 2)}
                                </label>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>);
    };

    const renderSaleOperations = () => {
        return (<>
            <Row>
                <div className="d-inline p-1 mt-3 heading-bg-primary">
                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Operations at Date of Sale</h5>
                </div>
            </Row>
            <Row>
                <Col md={6} sm={6} xs={6}>
                    {saleOpsModel?.isVisiblePropertyOperationsStatusTypeDescription &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Operations Status Type</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {saleOpsModel?.propertyOperationsStatusTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleFinancialsDataSource &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Financials Source/Data Type</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'>    {saleOpsModel?.financialsDataSource}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.reservesIncluded !== null &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Reserves Included</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {saleOpsModel?.reservesIncluded !== null ? formatYesNo(saleOpsModel?.reservesIncluded) : ''}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.managementIncluded !== null &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Management Included</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {saleOpsModel?.managementIncluded !== null ? formatYesNo(saleOpsModel?.managementIncluded) : ''}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.remainingLeaseTerm &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Remaining Lease Term</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'>{formatNumberWithNoDecimals(saleOpsModel?.remainingLeaseTerm)}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleOccupancyAtSale &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Actuals Occupancy</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {formatNumberWithDecimalsWithPercentage(saleOpsModel?.occupancyAtSale, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleAverageDailyRoomRate &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Average Daily Rate (ADR)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {formatNumberWithDecimalsWithCurrency(saleOpsModel?.averageDailyRoomRate, currencyTypeCode, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleRevenuePerAvailableRoom &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>RevPAR</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {formatNumberWithDecimalsWithCurrency(saleOpsModel?.revenuePerAvailableRoom, currencyTypeCode, 2)}</label>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col md={6} sm={6} xs={6}>
                    {saleOpsModel?.isVisibleCapRateReported &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Reported Cap Rate</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {formatNumberWithDecimalsWithPercentage(saleOpsModel?.capRateReported, 2)}</label>

                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleReportedCapRatePremise &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Reported Cap Rate Premise</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'>  {saleOpsModel?.reportedCapRatePremise}</label>

                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleMultipleEBitDA &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>EBITDA Multiple</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'>{formatNumberWithDecimals(saleOpsModel?.multipleEBitDA, 3)}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleNetIncomeMultiplier &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>NIM (Net Income Multiplier)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'>{formatNumberWithDecimals(saleOpsModel?.netIncomeMultiplier, 3)}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleRoomRevenueMultiplier &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Room Revenue Multiple</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'> {formatNumberWithDecimals(saleOpsModel?.roomRevenueMultiplier, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {saleOpsModel?.isVisibleProFormaStabOcc &&
                        <Row>
                            <Col xs={5}>
                                <label className='light-text'>Pro Forma Occupancy</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(saleOpsModel?.proFormaStabOcc, 2)}</label>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
            {shouldDisplayOperationsAtDateOfSaleSection() &&
                <Row>
                    <Col md={6} sm={6} xs={6}>
                        <Row>
                            <Col md={3} sm={3} xs={3}>
                                {shouldDisplayActualsSectionHeader() &&
                                    <label className='fw-bold'>Actuals</label>
                                }
                            </Col>
                            <Col md={3} sm={3} xs={3}></Col>
                            <Col className='text-end' md={3} sm={3} xs={3}>
                                {shouldDisplayActualsPerSFColumnHeader() &&
                                    <label className='fw-bold'>Per SF</label>
                                }
                            </Col>
                            <Col className='text-end' md={3} sm={3} xs={3}>
                                {shouldDisplayActualsPerUnitColumnHeader() &&
                                    <label className='fw-bold'>Per Unit</label>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                        <Row className="pe-4">
                            <Col md={3} sm={3} xs={3}>
                                {shouldDisplayProFormaSectionHeader() &&
                                    <label className='fw-bold '> Pro Forma</label>
                                }
                            </Col>
                            <Col md={3} sm={3} xs={3}></Col>
                            <Col className='text-end' md={3} sm={3} xs={3}>
                                {shouldDisplayProFormaPerSFColumnHeader() &&
                                    <label className='fw-bold'>Per SF</label>
                                }
                            </Col>
                            <Col className='text-end' md={3} sm={3} xs={3}>
                                {shouldDisplayProFormaPerUnitColumnHeader() &&
                                    <label className='fw-bold'>Per Unit</label>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            <Row>
                <Col md={6} sm={6} xs={6}><hr /></Col>
                <Col md={6} sm={6} xs={6}><hr /></Col>
            </Row>
            <Row>
                {shouldDisplayPGIRow() &&
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayActualsPGIRowLabel() &&
                                        <label className='light-text'>PGI</label>
                                    }
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualPGI ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualPGI, currencyTypeCode) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualPGIPerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.actualPGIPerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualPGIPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualPGIPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayProFormaPGIRowLabel() ?
                                        <label className='light-text ps-3'>PGI</label>
                                        : ''
                                    }
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabPGI ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabPGI, currencyTypeCode) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabPGIPerUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.proFormaStabPGIPerUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabPGIPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabPGIPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {shouldDisplayOtherIncomeRow() &&
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayActualsOtherIncomeRowLabel() &&
                                        <label className='light-text'>Other Income</label>
                                    }
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualOtherIncome ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualOtherIncome, currencyTypeCode) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualOtherIncomePerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.actualOtherIncomePerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualOtherIncomePerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualOtherIncomePerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayProFormaOtherIncomeRowLabel() ?
                                        <label className='light-text ps-3'>Other Income</label>
                                        : ''
                                    }
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabOtherIncome ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabOtherIncome, currencyTypeCode) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabOtherIncomePerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.proFormaStabOtherIncomePerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabOtherIncomePerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabOtherIncomePerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {shouldDisplayEgiRow() &&
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayActualsEGIRowLabel() &&
                                        <label className='light-text'>EGI</label>
                                    }
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualEGI ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualEGI, currencyTypeCode) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualEGIPerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.actualEGIPerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualEGIPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualEGIPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayProFormaEGIRowLabel() ?
                                        <label className='light-text ps-3'>EGI</label>
                                        : ''
                                    }
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabEGI ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabEGI, currencyTypeCode) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabEGIPerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.proFormaStabEGIPerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabEGIPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabEGIPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {shouldDisplayOpExpenseRow() &&
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayActualsOperatingExpenseRowLabel() &&
                                        <label className='light-text'>Operating Expense</label>
                                    }
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualOperatingExpense ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualOperatingExpense, currencyTypeCode) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualOperatingExpensePerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.actualOperatingExpensePerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualOperatingExpensePerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualOperatingExpensePerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayProFormaOPExpRowLabel() ?
                                        <label className='light-text ps-3'>Operating Expense</label>
                                        : ''
                                    }
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabOPExp ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabOPExp, currencyTypeCode) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabOPExpPerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.proFormaStabOPExpPerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabOPExpPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabOPExpPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {shouldDisplayNOIRow() &&
                    <Row>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayActualsNOIRowLabel() &&
                                        <label className='light-text'>NOI</label>
                                    }
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualNOI ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualNOI, currencyTypeCode) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualNOIPerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.actualNOIPerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className='text-end' md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleActualNOIPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.actualNOIPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6} sm={6} xs={6}>
                            <Row>
                                <Col md={3} sm={3} xs={3}>
                                    {shouldDisplayProFormaNOIRowLabel() ?
                                        <label className='light-text ps-3'>NOI</label>
                                        : ''
                                    }
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabNOI ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabNOI, currencyTypeCode) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabNOIPerSmallUOM ? formatNumberWithDecimalsWithCurrency(saleOpsModel?.proFormaStabNOIPerSmallUOM, currencyTypeCode, 2) : ''}
                                </Col>
                                <Col className="text-end" md={3} sm={3} xs={3}>
                                    {saleOpsModel?.isVisibleProFormaStabNOIPerUnit ? formatNumberNoDecimalsWithCurrency(saleOpsModel?.proFormaStabNOIPerUnit, currencyTypeCode) : ''}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </Row>
            {shouldDisplayFinancialDataSection() &&
                <Row>
                    <Row>
                        <div><label className='fw-bold'>Financial Indicators</label></div>
                    </Row>
                    <Row>
                        <hr />
                    </Row>
                    <Col md={6} sm={6} xs={6}>
                        {saleOpsModel?.isVisibleActualOperatingExpenseRationPercent &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>Actuals Operating Expense Ratio</label>
                                </Col>
                                <Col xs={7}>
                                    <label className='fw-normal'>
                                        {formatNumberWithDecimalsWithPercentage(saleOpsModel?.actualOperatingExpenseRationPercent, 2)}</label>
                                </Col>
                            </Row>
                        }
                        {saleOpsModel?.isVisibleActualCapRateDerivedPercent &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>Cap Rate Derived - Actuals</label>
                                </Col>
                                <Col xs={5}>
                                    <label className='fw-normal'>
                                        {formatNumberWithDecimalsWithPercentage(saleOpsModel?.actualCapRateDerivedPercent, 2)}</label>
                                </Col>
                            </Row>
                        }
                        {saleOpsModel?.isVisibleActualEGIMDerived &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>EGIM Derived - Actuals</label>
                                </Col>
                                <Col xs={5}>
                                    <label className='fw-normal'>
                                        {formatNumberWithDecimals(saleOpsModel?.actualEGIMDerived, 3)}</label>
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                        {saleOpsModel?.isVisibleProFormaStabOPExpRatio &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>Pro Forma Operating Expense Ratio</label>
                                </Col>
                                <Col xs={7}>
                                    <label className='fw-normal'>
                                        {formatNumberWithDecimalsWithPercentage(saleOpsModel?.proFormaStabOPExpRatio, 2)}</label>
                                </Col>
                            </Row>
                        }
                        {saleOpsModel?.isVisibleCapRateDerivedStabilized &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>Cap Rate Derived - Stabilized</label>
                                </Col>
                                <Col xs={7}>
                                    <label className='fw-normal'>
                                        {formatNumberWithDecimalsWithPercentage(saleOpsModel?.capRateDerivedStabilized, 2)}</label>
                                </Col>
                            </Row>
                        }
                        {saleOpsModel?.isVisibleDerivedEGIM &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>EGIM Derived - Stabilized</label>
                                </Col>
                                <Col xs={5}>
                                    <label className='fw-normal'>
                                        {formatNumberWithDecimals(saleOpsModel?.derivedEGIM, 3)}</label>
                                </Col>
                            </Row>
                        }
                        {saleOpsModel?.isVisibleReportedDiscountRate &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>Discount Rate</label>
                                </Col>
                                <Col xs={7}>
                                    <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(saleOpsModel?.reportedDiscountRate, 2)}</label>
                                </Col>
                            </Row>
                        }
                        {saleOpsModel?.isVisibleReportedReversionCapRate &&
                            <Row>
                                <Col xs={5}>
                                    <label className='light-text'>Reversion Cap Rate</label>
                                </Col>
                                <Col xs={7}>
                                    <label className='fw-normal'> {formatNumberWithDecimalsWithPercentage(saleOpsModel.reportedReversionCapRate, 2)}</label>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            }
        </>);
    };
    return (
        <div style={{ position: "relative" }}>
            <div className='watermark'></div>
            <div className='col-md-12 p-5 sale-print-full-width bg-white mb-5 col-print-12'>
                <div className='row'>
                    <div className='d-inline col-6 p-0 mb-3'>
                        <h2 className='fw-bold'>{getSaleTitle()}</h2>
                    </div>
                    <div className='d-flex col-6 p-0 justify-content-end'>
                        <span className='logo-NKF-report'></span>
                    </div>
                </div>
                <div className='row'>
                    <div className="d-inline ps-2 p-1 heading-bg-primary">
                        <h2>{reportPropertyEventModel?.address1}</h2>
                        <h6>{reportPropertyEventModel?.name}</h6>
                    </div>
                </div>
                {saleEvent.staticMapImageUrl && !reportPropertyEventModel?.isConfidential &&
                    <div className=''>
                        <div className='row mt-1'>
                            <img className="p-0 img-fluid" src={saleEvent.staticMapImageUrl} alt='Map' />
                        </div>
                    </div>
                }
                {saleEvent &&
                    <PropertyEventShared sale={saleEvent} compTypeId={1} rental={null} incomeExpense={null} />
                }
                {isPropertySaleModelVisible &&
                    renderSaleInformation()
                }
                {isPropertySaleOpsModelVisible &&
                    renderSaleOperations()
                }
                {(reportPropertyEventModel?.isVisibleComments || reportPropertyEventModel?.isVisibleEventComments) &&
                    <PropertyAndEventComments data={reportPropertyEventModel} />
                }
            </div>
        </div>
    );
};
export default Sale;