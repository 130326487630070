// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportPropertyEvents } from "dto/reports/reportPropertyEvents";

import type { RootState } from "store";

const initialState: ReportPropertyEvents = {     
    saleEvents: null,
    rentalEvents: null,
    incomeAndExpenseEvents: null
};

export const reportPropertyEventsSlice = createSlice({ 
    name: "reportPropertyEvents", 
    initialState, 
    reducers: { 
        load( 
            state: ReportPropertyEvents, 
            action: PayloadAction<ReportPropertyEvents> ) { 
                state.saleEvents = action.payload.saleEvents; 
                state.rentalEvents = action.payload.rentalEvents; 
                state.incomeAndExpenseEvents = action.payload.incomeAndExpenseEvents; 
            },                
        },
 });

 export const { load } = reportPropertyEventsSlice.actions; 

 export const selectReportPropertyEventsSelected = (state: RootState): ReportPropertyEvents =>
    state.reportPropertyEvents;

 export default reportPropertyEventsSlice.reducer;