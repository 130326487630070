import { ReportIncomeAndExpenseEventModel } from 'dto/reports/reportIncomeExpenseEvent';
import PropertyEventShared from './propertyEventShared';
import PropertyAndEventComments from './propertyEventComments';
import { applyIEConfidential, setIncomeExpenseEventModelVisibility } from 'components/utils/reports/reportVisibility';
import { PrepReportModel } from 'components/utils/reports/incomeExpensePrepReport';
import { ReportPropertyIncomeAndExpenseSummaryEventModel } from 'dto/reports/shared/reportPropertyIncomeAndExpenseSummaryEvent';
import { Col, Row } from 'reactstrap';
import { displayPatternValue, formatNumberNoDecimalsWithCurrency, formatNumberWithDecimals, formatNumberWithDecimalsWithCurrency, formatNumberWithDecimalsWithPercentage, formatNumberWithNoDecimals } from 'components/utils/numberFormatting/numberFormat';
import { dateFormatter } from 'components/utils/columnFormatting/date';

const IncomeExpense = ({ data }: { data: ReportIncomeAndExpenseEventModel }) => {
    let ieEventModel = data as ReportIncomeAndExpenseEventModel;
    let ieEvent: ReportIncomeAndExpenseEventModel;
    let ieEventVisibleModel = setIncomeExpenseEventModelVisibility(ieEventModel);
    if (ieEventVisibleModel?.reportPropertyEventModel?.isConfidential) {
        ieEvent = applyIEConfidential(ieEventVisibleModel);
    }
    else
        ieEvent = ieEventVisibleModel;

    let ieReportPrepModel = PrepReportModel(ieEventModel);
    let ieSummaryEvents: ReportPropertyIncomeAndExpenseSummaryEventModel[] | null = ieReportPrepModel.reportPropertyIncomeAndExpenseSummaryEventModels;
    let reportPropertyEventModel = ieEvent.reportPropertyEventModel;
    const currencyTypeCode: string = reportPropertyEventModel ? reportPropertyEventModel.currencyTypeCode : 'USD';
    const currencySymbol: string = reportPropertyEventModel ? reportPropertyEventModel.currencyTypeSymbol : '$';

    function getIETitle(): string {
        let propertyType = reportPropertyEventModel?.propertyTypeDescription || '';
        let propertyEventType = reportPropertyEventModel?.propertyEventTypeDescription || '';

        return propertyType + ' ' + propertyEventType;
    }
    const sortBySequenceNumber = (a: ReportPropertyIncomeAndExpenseSummaryEventModel, b: ReportPropertyIncomeAndExpenseSummaryEventModel) => {
        if (a.sequenceNumber === null && b.sequenceNumber === null)
            return 0;
        if (a.sequenceNumber === null)
            return -1;
        if (b.sequenceNumber === null)
            return 1;
        return a.sequenceNumber - b.sequenceNumber;
    }

    function isNumericDisplayFormat(numericFormatTypeId: number | null): boolean {
        if (numericFormatTypeId === null)
            return false;
        return numericFormatTypeId > 0 && numericFormatTypeId !== 6 ? true : false
    }

    const renderIncomeExpense = () => {
        return (
            <>
                {ieSummaryEvents && ieSummaryEvents.length > 0 && (
                    <>
                        <Row>
                            <div className="d-inline p-1 mt-3 heading-bg-primary">
                                <h5 className="title ps-2 p-1 mb-1 fw-bold">Income & Expenses</h5>
                            </div>
                        </Row>
                        <Row className='pb-3'>
                            <Col md={4} sm={4} xs={4}>
                                <Row>
                                    <Col xs={7}>
                                        <label className="light-text">Period Length</label>
                                    </Col>
                                    <Col xs={5}>
                                        <label className="fw-normal ps-8">
                                            {ieSummaryEvents[0].periodLength}
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={7}>
                                        <label className="light-text">Period Ending</label>
                                    </Col>
                                    <Col xs={5}>
                                        <label className="fw-normal ps-8">
                                            {dateFormatter(ieSummaryEvents[0].periodEnd)}
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5} sm={5} xs={5}>
                                <Row>
                                    <Col xs={7}>
                                        <label className="light-text">Occupancy %</label>
                                    </Col>
                                    <Col xs={5}>
                                        <label className="fw-normal ps-8">
                                            {formatNumberWithDecimalsWithPercentage(
                                                ieSummaryEvents[0].occupancyPercent,
                                                2
                                            )}
                                        </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={7}>
                                        <label className="light-text">Source</label>
                                    </Col>
                                    <Col xs={5}>
                                        <label className="fw-normal ps-8">
                                            {
                                                ieSummaryEvents[0]
                                                    .incomeAndExpenseSourceTypeDescription
                                            }
                                        </label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className='border'>
                                <h5 className='fw-bold'>Income</h5>
                            </Row>
                            <Row>
                                {ieSummaryEvents && Array.isArray(ieSummaryEvents) && ieSummaryEvents.filter(item => item.glAccountTypeId === 1).sort(sortBySequenceNumber).map((incomeItem, index) => {
                                    if (incomeItem.isHidden === null || incomeItem.isHidden === false) {
                                        return (
                                            <div key={index}>
                                                <Row className={`line-item-style-${incomeItem.glAccountLineItemTypeId} ${incomeItem.isBold ? 'fw-bold' : 'light-text'}`}>
                                                    <Col xs={incomeItem.colSize} className={`col-style-${incomeItem.indentLevel} pb-3`}>
                                                        {incomeItem.lineItemDescription}
                                                    </Col>
                                                    <Col xs={2} className={`text-end ${incomeItem.glAccountLineItemTypeId !== 5 && !incomeItem.isBold ? 'fw-normal' : ''}`}>
                                                        {incomeItem.glAccountLineItemTypeId === 5
                                                            ? 'Total'
                                                            : incomeItem.glAccountLineItemTypeId === 6
                                                                ? ''
                                                                : isNumericDisplayFormat(incomeItem.numericFormatTypeId) && incomeItem.displayPattern
                                                                    ? (incomeItem.amount !== null ? displayPatternValue(incomeItem.displayPattern, incomeItem.amount, currencyTypeCode) : displayPatternValue(incomeItem.displayPattern, 0, currencyTypeCode))
                                                                    : (incomeItem.amount !== null ? formatNumberNoDecimalsWithCurrency(incomeItem.amount, currencyTypeCode) : formatNumberNoDecimalsWithCurrency(0, currencyTypeCode))
                                                        }
                                                    </Col>

                                                    {!incomeItem.hideAmountPerSFCol && (
                                                        <Col xs={1} className={`text-end ${incomeItem.glAccountLineItemTypeId !== 5 && !incomeItem.isBold ? 'fw-normal' : ''}`}>
                                                            {incomeItem.glAccountLineItemTypeId === 5
                                                                ? `${currencySymbol}/SF`
                                                                : incomeItem.glAccountLineItemTypeId === 6 || incomeItem.glAccountId === 159
                                                                    ? ''
                                                                    : isNumericDisplayFormat(incomeItem.numericFormatTypeId)
                                                                        ? formatNumberWithDecimalsWithCurrency(incomeItem.amountPerSmallUnitOfMeasure, currencyTypeCode, 2)
                                                                        : formatNumberWithDecimals(incomeItem.amountPerSmallUnitOfMeasure, 2)
                                                            }
                                                        </Col>
                                                    )}

                                                    {!incomeItem.hideAmountPerUnitCol && (
                                                        <Col xs={1} className={`text-end ${incomeItem.glAccountLineItemTypeId !== 5 && !incomeItem.isBold ? 'fw-normal' : ''}`}>
                                                            {incomeItem.glAccountLineItemTypeId === 5
                                                                ? (incomeItem.propertyIncomeAndExpenseEventShortDescription
                                                                    ? (`${currencySymbol}/${incomeItem.propertyIncomeAndExpenseEventShortDescription}`)
                                                                    : `${currencySymbol}/Unit`)
                                                                : incomeItem.glAccountLineItemTypeId === 6 || incomeItem.glAccountId === 159
                                                                    ? ''
                                                                    : isNumericDisplayFormat(incomeItem.numericFormatTypeId)
                                                                        ? displayPatternValue(incomeItem.displayPattern, incomeItem.amountPerDemisedUnit, currencyTypeCode)
                                                                        : formatNumberNoDecimalsWithCurrency(incomeItem.amountPerDemisedUnit, currencyTypeCode)
                                                            }
                                                        </Col>
                                                    )}

                                                    <Col xs={1} className={`text-end ${incomeItem.glAccountLineItemTypeId !== 5 && !incomeItem.isBold ? 'fw-normal' : ''}`}>
                                                        {incomeItem.glAccountLineItemTypeId === 5
                                                            ? '% of Income'
                                                            : incomeItem.glAccountLineItemTypeId === 6 || incomeItem.glAccountId === 159
                                                                ? ''
                                                                : formatNumberWithDecimalsWithPercentage(incomeItem.percentOfEffectiveGrossIncome, 2)
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }
                                })}
                        </Row>
                        <Row className='border'>
                                <h5 className='fw-bold'>Expenses</h5>
                            </Row>
                            <Row>
                                {ieSummaryEvents && Array.isArray(ieSummaryEvents)
                                    && ieSummaryEvents.filter(item => item.glAccountTypeId === 2).sort(sortBySequenceNumber)
                                        .map((expItem, index) => {
                                            if (expItem.isHidden === null || expItem.isHidden === false) {
                                                const shouldShowItem = expItem.glAccountLineItemTypeId === 5 || expItem.glAccountLineItemTypeId === 6 || expItem.editable || (!expItem.editable && expItem.amount !== null) || (expItem.percentOfEffectiveGrossIncome !== null && expItem.percentOfEffectiveGrossIncome !== 0);
                                                if (shouldShowItem) {
                                                    return (
                                                        <div key={index}>
                                                            {expItem.glAccountLineItemTypeId === 5 && (
                                                                <div className=""></div>
                                                            )}
                                                            <Row className={`line-item-style-${expItem.glAccountLineItemTypeId} ${expItem.isBold ? 'fw-bold' : 'light-text'}`}>
                                                                <Col xs={expItem.colSize} className={`col-style-${expItem.indentLevel}`}>
                                                                    {expItem.lineItemDescription}
                                                                </Col>
                                                                <Col xs={2} className={`text-end ${expItem.glAccountLineItemTypeId !== 5 && !expItem.isBold ? 'fw-normal' : ''}`}>
                                                                    {expItem.glAccountLineItemTypeId === 5 ? 'Total' :
                                                                        expItem.glAccountLineItemTypeId === 6 ? '' :
                                                                        isNumericDisplayFormat(expItem.numericFormatTypeId) && expItem.displayPattern !== null ?
                                                                            expItem.amount !== null ? displayPatternValue(expItem.displayPattern, expItem.amount, currencyTypeCode) : displayPatternValue(expItem.displayPattern, 0, currencyTypeCode)
                                                                            : expItem.amount !== null ? formatNumberNoDecimalsWithCurrency(expItem.amount, currencyTypeCode) : formatNumberNoDecimalsWithCurrency(0, currencyTypeCode)}
                                                                </Col>

                                                                {!expItem.hideAmountPerSFCol && (
                                                                    <Col xs={1} className={`text-end ${expItem.glAccountLineItemTypeId !== 5 && !expItem.isBold ? 'fw-normal' : ''}`}>
                                                                        {expItem.glAccountLineItemTypeId === 5
                                                                            ? `${currencySymbol}/SF`
                                                                            : expItem.glAccountLineItemTypeId === 6 || expItem.glAccountId === 159
                                                                                ? ''
                                                                                : isNumericDisplayFormat(expItem.numericFormatTypeId)
                                                                                    ? formatNumberWithDecimalsWithCurrency(expItem.amountPerSmallUnitOfMeasure, currencyTypeCode, 2)
                                                                                    : formatNumberWithDecimals(expItem.amountPerSmallUnitOfMeasure, 2)}
                                                                    </Col>
                                                                )}

                                                                {!expItem.hideAmountPerUnitCol && (
                                                                    <Col xs={1} className={`text-end ${expItem.glAccountLineItemTypeId !== 5 && !expItem.isBold ? 'fw-normal' : ''}`}>
                                                                        {expItem.glAccountLineItemTypeId === 5
                                                                            ? expItem.propertyIncomeAndExpenseEventShortDescription
                                                                                ? `${currencySymbol}/${expItem.propertyIncomeAndExpenseEventShortDescription}`
                                                                                : `${currencySymbol}/Unit`
                                                                            : expItem.glAccountLineItemTypeId === 6 || expItem.glAccountId === 159
                                                                                ? ''
                                                                                : isNumericDisplayFormat(expItem.numericFormatTypeId)
                                                                                    ? displayPatternValue(expItem.displayPattern, expItem.amountPerDemisedUnit, currencyTypeCode)
                                                                                    : formatNumberWithNoDecimals(expItem.amountPerDemisedUnit)}
                                                                    </Col>
                                                                )}

                                                                <Col xs={1} className={`text-end ${expItem.glAccountLineItemTypeId !== 5 && !expItem.isBold ? 'fw-normal' : ''}`}>
                                                                    {expItem.glAccountLineItemTypeId === 5
                                                                        ? '% of EGI'
                                                                        : expItem.glAccountLineItemTypeId === 6 || expItem.glAccountId === 159
                                                                            ? ''
                                                                            : formatNumberWithDecimalsWithPercentage(expItem.percentOfEffectiveGrossIncome, 2)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                }
                                            }
                                        })
                            }
                        </Row>
                    </>
                )}
            </>
        );
    }
    return (
        <div style={{ position: "relative" }}>
            <div className='watermark'></div>
            <div className='col-md-12 p-5 shadow-sm bg-white border mb-5'>
                <div className='row'>
                    <div className='d-inline col-6 p-0 mb-3'>
                        <h2 className='fw-bold'>{getIETitle()}</h2> 
                    </div>
                    <div className='d-flex col-6 p-0 justify-content-end'>
                        <span className='logo-NKF-report'></span>
                    </div>
                </div>
                <div className='row'>
                    <div className="d-inline p-1 heading-bg-primary">
                        <h2>{reportPropertyEventModel?.address1}</h2> {/**/}
                        <h6>{reportPropertyEventModel?.name}</h6> {/**/}
                    </div>
                </div>
                {ieEvent.staticMapImageUrl && !reportPropertyEventModel?.isConfidential &&
                <div className=''>
                    <div className='row mt-1'>
                        <img className="p-0 img-fluid" src={ieEvent.staticMapImageUrl} alt='Map' />                       
                    </div>
                </div>
                }
                {ieEvent &&
                <PropertyEventShared incomeExpense={ieEvent} compTypeId={3} rental={null} sale={null} />
                }
                {renderIncomeExpense()}
                {(reportPropertyEventModel?.isVisibleComments || reportPropertyEventModel?.isVisibleEventComments) &&
                <PropertyAndEventComments data={reportPropertyEventModel} />
                }
            </div>
        </div>
    );
};
export default IncomeExpense;