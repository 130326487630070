import NativeDataRoomListComponent from "../native/dataRoomList";
import {
  getAllAssignmentData,
  getAllDataRoomData,
} from "components/api/dataRoomSearchData";
import { useAppSelector, useAppDispatch } from "hooks";
import { AppDispatch } from "store";
import { DataRoom } from "dto/dataRoom";
import { useEffect, useState } from "react";
import { selectIdentity } from "slices/user/identitySlice";
import { Identity } from "dto/identity";
import { Assignment } from "dto/assignment";
import ValuationsDataRoomListComponent from "../valuations/dataRoomList";
import { selectDataRoomList } from "slices/dataRoom/dataRoomListSlice";
import { selectAssignmentList } from "slices/assignment/assignmentListSlice";
import HeaderTemplate from "components/shared/header";
import Loading from "components/shared/loading";

function getAllNativeDataRoomDataWrapper(
  identity: Identity,
  dispatch: AppDispatch,
  setLoading: (loading: any) => void,
  setHasData: (hasData: boolean) => void
) {
  getAllDataRoomData(identity)
    .then(function (data: DataRoom[]) {
      setLoading(false);
      setHasData(data.length > 0);
      return dispatch({ type: "dataRoomList/load", payload: data });
    })
    .catch((error) => console.error("Errojr fetching data:", error));
}

function getAllValuationDataRoomDataWrapper(
  identity: Identity,
  dispatch: AppDispatch,
  setLoading: (loading: boolean) => void,
  setHasData: (hasData: boolean) => void
) {
  getAllAssignmentData(identity)
    .then(function (data: Assignment[]) {
      setLoading(false);
      setHasData(data.length > 0);
      return dispatch({ type: "assignmentList/load", payload: data });
    })
    .catch((error) => console.error("Error fetching data:", error));
}

function getCustomHeight({
  nativeDataRooms,
  valuationsDataRooms,
}: {
  nativeDataRooms: DataRoom[];
  valuationsDataRooms: Assignment[];
}) {
  const enableNativeDataRooms = !localStorage.FEATURE_TOGGLE_disableNative;
  const componentCount =
    nativeDataRooms.length > 0 &&
    enableNativeDataRooms &&
    valuationsDataRooms.length > 0
      ? 2
      : 1;
  const customHeight = `calc(80vh / ${componentCount})`; // Removed 20vh for header and footer heights
  return customHeight;
}

export default function LandingPage() {
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  const [isNativeLoading, setIsNativeLoading] = useState(true);
  const [isValuationsLoading, setIsValuationsLoading] = useState(true);

  const [shouldRenderNative, setShouldRenderNative] = useState(false);
  const [shouldRenderValuations, setShouldRenderValuations] = useState(false);
  const nativeDataRooms = useAppSelector(selectDataRoomList);
  const valuationsDataRooms = useAppSelector(selectAssignmentList);
  useEffect(() => {
    // Fetch data from the API when the component mounts
    const refreshValuations = () => {
      if (isValuationsLoading) {
        dispatch({ type: "assignmentList/clearAssignmentList" });
      }
      // If the client doesn't have any data room, then allow brokers to add data rooms and the client can refresh the page (instead of auto-update)
      getAllValuationDataRoomDataWrapper(
        identity,
        dispatch,
        setIsValuationsLoading,
        setShouldRenderValuations
      );
    };
    refreshValuations();
    const intervalId = setInterval(
      refreshValuations,
      parseInt(process.env.REACT_APP_REFRESH_INTERVAL!)
    );
    return () => clearInterval(intervalId);
  }, [identity, dispatch]);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const refreshNative = () => {
      getAllNativeDataRoomDataWrapper(
        identity,
        dispatch,
        setIsNativeLoading,
        setShouldRenderNative
      );
    };
    refreshNative();
    const intervalId = setInterval(
      refreshNative,
      parseInt(process.env.REACT_APP_REFRESH_INTERVAL!)
    );

    return () => clearInterval(intervalId);
  }, [identity, dispatch]);
  return (
    <>
      {isNativeLoading ? (
        <Loading />
      ) : (
        shouldRenderNative && (
          <NativeDataRoomListComponent
            style={{
              height: getCustomHeight({
                nativeDataRooms,
                valuationsDataRooms,
              }),
            }}
          />
        )
      )}
      {isValuationsLoading ? (
        <Loading />
      ) : (
        shouldRenderValuations && (
          <ValuationsDataRoomListComponent
            style={{
              marginTop: "3rem",
              height: getCustomHeight({
                nativeDataRooms,
                valuationsDataRooms,
              }),
            }}
          />
        )
      )}
    </>
  );
}
