import { Identity } from "dto/identity";
import { AxiosResponse } from "axios";
import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import DATA_PROVIDERS from "enums/dataProviders";
import { ReportPropertyEvents } from "dto/reports/reportPropertyEvents";
import ReportOperation from "enums/reportOperation";
import { PropertyStorageEventModel } from "dto/propertyStorageEvent";

async function _performDataOp(
  identity: Identity,
  dataOp: DATA_OPERATIONS,
  Operation: ReportOperation,
  { data }: { data?: any} = {}
): Promise<any> {
  let endpoint: string = process.env.REACT_APP_APIM_DOCUMENT!;
  let response: AxiosResponse;
  try {
    response = await makeHttpCall(identity, dataOp, endpoint, {
    data, dataProvider: DATA_PROVIDERS.VALUATIONS,
    });
  } catch (e) {
      throw e;    
  }
  if (response?.status < 300) {
    if(Operation === ReportOperation.DATASHEET){
      return response.data as ReportPropertyEvents;
    }
  else{
    return response.data.data as PropertyStorageEventModel[];
    }
  }
  return [];
}

export async function getReportDataSheet(
  identity: Identity,
  jobAssignmentPropertyId: number,
  compTypeId: number
): Promise<ReportPropertyEvents> {
  const data = {
    jobAssignmentPropertyId: jobAssignmentPropertyId,
    compTypeId: compTypeId,
    operation: ReportOperation.DATASHEET.toString()
  };

  return await _performDataOp(identity, DATA_OPERATIONS.CREATE, ReportOperation.DATASHEET, { data });
}
export async function getPhotos(
  identity: Identity,
  jobAssgnmentPropertyId: number,
  propEventId: number
): Promise<PropertyStorageEventModel[]> {
  const data = {
    jobAssignmentPropertyId: jobAssgnmentPropertyId,
    propertyEventId: propEventId,
    operation: ReportOperation.PHOTOS.toString()
  };

  return await _performDataOp(identity, DATA_OPERATIONS.CREATE, ReportOperation.PHOTOS, { data });
}
