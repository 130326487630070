//Commenting fields which are returned from API but not used in Report,
//so that ModelVisibility is not true for unused fields
import { ReportIncomeAndExpenseEventModel } from "dto/reports/reportIncomeExpenseEvent";
import { ReportRentalSurveyEventModel } from "dto/reports/reportRentalSurveyEvent";
import { ReportSaleEventModel } from "dto/reports/reportSaleEvent";
import { ReportPropertyCommercialSpaceModel } from "dto/reports/shared/reportPropertyCommercialSpace";
import { ReportPropertyEventModel } from "dto/reports/shared/reportPropertyEvent";
import { ReportPropertyImprovementDetailEventModel } from "dto/reports/shared/reportPropertyImprovementDetailEvent";
import { ReportPropertyImprovementLeaseDetailEventModel } from "dto/reports/shared/reportPropertyImprovementLeaseDetailEvent";
import { ReportPropertyIndustrialBuildingDetailEventModel } from "dto/reports/shared/reportPropertyIndustrialBuildingDetailEvent";
import { ReportPropertyLeaseDetailEventModel } from "dto/reports/shared/reportPropertyLeaseDetailEvent";
import { ReportPropertyRentalSurveyEventModel } from "dto/reports/shared/reportPropertyRentalSurveyEvent";
import { ReportPropertySaleEventModel } from "dto/reports/shared/reportPropertySaleEvent";
import { ReportPropertySaleOperationsAtDateOfSaleEventModel } from "dto/reports/shared/reportPropertySaleOperationsAtDateOfSaleEvent";
import { ReportPropertySiteDetailEventModel } from "dto/reports/shared/reportPropertySiteDetailEvent";

const confidential: string = 'Confidential';

function isVisible(val: any | null | undefined): boolean {
  if (val === null || val === undefined) {
    return false;
  }

  if (typeof val === "number" && val === 0) {
    return false;
  }
  if (typeof val === "boolean" && val === false) {
    return false;
  }
  if (typeof val === "string" && val.trim() === "") {
    return false;
  }
  if (val instanceof Date && !isValidDate(val)) {
    return false;
  }

  return true;
}

function isVisibleBoolean (val: boolean | null | undefined): boolean { 
  if (val !== null && val !== undefined) {
     return true; 
    } 
    return false; 
};

function isValidDate(d: Date): boolean {
  return d instanceof Date && !isNaN(d.getTime());
}

export function isModelVisible(src: any): boolean {
  if (!src) {
    return false;
  }

  const propVisibilityList: boolean[] = [];

  const props = Object.keys(src);
  for (const propName of props) {
    if (propName.toLowerCase().startsWith("isvisible")) {
      const isVisible = !!src[propName]; // Convert to boolean
      if (isVisible) {
        return true;
      }
      propVisibilityList.push(isVisible);
    }
  }

  // If a model doesn't have visibility properties, return true
  // If it does, then return true if any of them are true
  if (propVisibilityList.length === 0) {
    return true;
  }

  return false;
}

export function setSaleEventModelVisibility(
  model: ReportSaleEventModel
): ReportSaleEventModel {
  return {
    ...model,
    reportPropertyEventModel: setPropertyEventVisibility(model.reportPropertyEventModel),
    reportPropertySiteDetailEventModel: setSiteDetailVisibility(
      model.reportPropertySiteDetailEventModel
    ),
    reportPropertyImprovementDetailEventModel: setImprovementDetailVisibility(
      model.reportPropertyImprovementDetailEventModel
    ),
    reportPropertyImprovementLeaseDetailEventModel: setImprovementLeaseDetailVisibility(model.reportPropertyImprovementLeaseDetailEventModel),
    reportPropertyIndustrialBuildingDetailEventModel: setIndustrialBuildingDetailVisibility(model.reportPropertyIndustrialBuildingDetailEventModel),
    reportPropertySaleEventModel: setSaleEventVisibility(model.reportPropertySaleEventModel),
    reportPropertySaleOperationsAtDateOfSaleEventModel: setSaleOperationsVisibility(model.reportPropertySaleOperationsAtDateOfSaleEventModel),

  };
}
export function setRentalSurveyEventModelVisibility(
  model: ReportRentalSurveyEventModel
): ReportRentalSurveyEventModel {
  return {
    ...model,
    reportPropertyEventModel: setPropertyEventVisibility(model.reportPropertyEventModel),
    reportPropertySiteDetailEventModel: setSiteDetailVisibility(
      model.reportPropertySiteDetailEventModel
    ),
    reportPropertyImprovementDetailEventModel: setImprovementDetailVisibility(
      model.reportPropertyImprovementDetailEventModel
    ),
    reportPropertyImprovementLeaseDetailEventModel: setImprovementLeaseDetailVisibility(model.reportPropertyImprovementLeaseDetailEventModel),
    reportPropertyIndustrialBuildingDetailEventModel: setIndustrialBuildingDetailVisibility(model.reportPropertyIndustrialBuildingDetailEventModel),
    reportPropertyRentalSurveyEventModel: setRentalSurveyVisibility(model.reportPropertyRentalSurveyEventModel),
    reportPropertyLeaseDetailEventModels: model.reportPropertyLeaseDetailEventModels?.map(setLeaseDetailVisibility) 
  };
}

export function setIncomeExpenseEventModelVisibility(
  model: ReportIncomeAndExpenseEventModel
): ReportIncomeAndExpenseEventModel {
  return {
    ...model,
    reportPropertyEventModel: setPropertyEventVisibility(model.reportPropertyEventModel),
    reportPropertySiteDetailEventModel: setSiteDetailVisibility(
      model.reportPropertySiteDetailEventModel
    ),
    reportPropertyImprovementDetailEventModel: setImprovementDetailVisibility(
      model.reportPropertyImprovementDetailEventModel
    ),
    reportPropertyImprovementLeaseDetailEventModel: setImprovementLeaseDetailVisibility(model.reportPropertyImprovementLeaseDetailEventModel),
    reportPropertyIndustrialBuildingDetailEventModel: setIndustrialBuildingDetailVisibility(model.reportPropertyIndustrialBuildingDetailEventModel)
  };
}

export function setSiteDetailVisibility(
  model: ReportPropertySiteDetailEventModel | null
): ReportPropertySiteDetailEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    isVisibleZoning: isVisible(model.zoning),
    isVisibleZoningDescription: isVisible(model.zoningDescription),
    isVisibleFloodMap: isVisible(model.floodMap),
    isVisibleFloodMapDate: isVisible(model.floodMapDate),
    isVisiblePropertyFloodInsuranceRequiredTypeDescription: isVisible(
      model.propertyFloodInsuranceRequiredTypeDescription
    ),
    isVisibleSiteShape: isVisible(model.siteShape),
    isVisibleSiteTopography: isVisible(model.siteTopography),
    isVisibleSourceOfLandInfo: isVisible(model.sourceOfLandInfo),
    isVisibleUsableGrossRatioSmallUOM: isVisible(
      model.usableGrossRatioSmallUOM
    ),
    isVisiblePotentialFloorAreaRatio: isVisible(model.potentialFloorAreaRatio),
    isVisiblePotentialBuildingUnitDensity: isVisible(
      model.potentialBuildingUnitDensity
    ),
    isVisiblePotentialUnits: isVisible(model.potentialUnits),
    isVisibleVegetation: isVisible(model.vegetation),
    isVisibleCornerLot: isVisibleBoolean(model.cornerLot),
    isVisiblePropertyTrafficControlAtEntryTypeId: isVisible(
      model.propertyTrafficControlAtEntryTypeId
    ),
    isVisiblePropertyTrafficControlAtEntryTypeDescription: isVisible(
      model.propertyTrafficControlAtEntryTypeDescription
    ),
    isVisiblePropertyTrafficFlowTypeId: isVisible(
      model.propertyTrafficFlowTypeId
    ),
    isVisiblePropertyTrafficFlowTypeDescription: isVisible(
      model.propertyTrafficFlowTypeDescription
    ),
    isVisiblePropertyAccessibilityRatingTypeId: isVisible(
      model.propertyAccessibilityRatingTypeId
    ),
    isVisiblePropertyAccessibilityRatingTypeDescription: isVisible(
      model.propertyAccessibilityRatingTypeDescription
    ),
    isVisiblePropertyVisibilityRatingTypeId: isVisible(
      model.propertyVisibilityRatingTypeId
    ),
    isVisiblePropertyVisibilityRatingTypeDescription: isVisible(
      model.propertyVisibilityRatingTypeDescription
    ),
    isVisibleFrontageStreetName: isVisible(model.frontageStreetName),
    isVisibleFrontageSmallUOM: isVisible(model.frontageSmallUOM),
    isVisiblePropertyMarketOrientationTypeId: isVisible(
      model.propertyMarketOrientationTypeId
    ),
    isVisiblePropertyUtilitiesAvailableTypes: isVisible(
      model.propertyUtilitiesAvailableTypes
    ),
    isVisiblePropertyOtherCharacteristicsTypes: isVisible(
      model.propertyOtherCharacteristicsTypes
    ),
    isVisiblePropertyFloodZoneDesignationTypes: isVisible(
      model.propertyFloodZoneDesignationTypes
    ),
    isVisibleSecondaryFrontageStreetName: isVisible(
      model.secondaryFrontageStreetName
    ),
    isVisibleSecondaryFrontageSmallUOM: isVisible(
      model.secondaryFrontageSmallUOM
    ),
    isVisibleAdditionalFrontageStreetName: isVisible(
      model.additionalFrontageStreetName
    ),
    isVisibleAdditionalFrontageSmallUOM: isVisible(
      model.additionalFrontageSmallUOM
    ),
    isVisiblePermittedUses: isVisible(model.permittedUses),
    isVisibleLegallyConforming: isVisible(model.legallyConforming),
    isVisibleZoningChange: isVisible(model.zoningChange),
    isVisibleRailAccess: isVisible(model.railAccess),
  };
}

export function setImprovementDetailVisibility(
  model: ReportPropertyImprovementDetailEventModel | null
): ReportPropertyImprovementDetailEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    isVisibleRentableAreaSmallUOM: isVisible(model.rentableAreaSmallUOM),
    isVisiblePropertyDemisedUnitOfComparisonTypeId: isVisible(
      model.propertyDemisedUnitOfComparisonTypeId
    ),
    isVisiblePropertyDemisedUnitOfComparisonTypeDescription: isVisible(
      model.propertyDemisedUnitOfComparisonTypeDescription
    ),
    isVisiblePropertyDemisedUnitOfComparisonTypeShortDescription: isVisible(
      model.propertyDemisedUnitOfComparisonTypeShortDescription
    ),
    isVisibleNumberOfDemisedUnits: isVisible(model.numberOfDemisedUnits),
    isVisibleAverageUnitSizeSmallUOM: isVisible(model.averageUnitSizeSmallUOM),
    isVisibleGrossBuildingAreaSmallUOM: isVisible(
      model.grossBuildingAreaSmallUOM
    ),
    isVisiblePropertyConditionTypeId: isVisible(model.propertyConditionTypeId),
    isVisiblePropertyConditionTypeDescription: isVisible(
      model.propertyConditionTypeDescription
    ),
    isVisibleYearBuilt: isVisible(model.yearBuilt),
    isVisibleYearRenovated: isVisible(model.yearRenovated),
    isVisibleNumberOfBuildings: isVisible(model.numberOfBuildings),
    isVisibleNumberOfStoriesFloors: isVisible(model.numberOfStoriesFloors),
    isVisibleElevatorsCount: isVisible(model.elevatorsCount),
    isVisibleCeilingHeightMaxSmallUOM: isVisible(
      model.ceilingHeightMaxSmallUOM
    ),
    isVisibleCeilingHeightMinSmallUOM: isVisible(
      model.ceilingHeightMinSmallUOM
    ),
    isVisibleClearHeightSmallUOM: isVisible(model.clearHeightSmallUOM),
    isVisibleFireSprinklerType: isVisible(model.fireSprinklerType),
    isVisibleNumberParkingSpaces: isVisible(model.numberParkingSpaces),
    isVisibleParkingSpacesPerRentableAreaSmallUOM: isVisible(
      model.parkingSpacesPerRentableAreaSmallUOM
    ),
    isVisibleParking: isVisible(model.parking),
    isVisibleLandToBuildingRatio: isVisible(model.landToBuildingRatio),
    isVisibleBuildingInvestmentClassTypeId: isVisible(
      model.buildingInvestmentClassTypeId
    ),
    isVisibleBuildingInvestmentClassTypeDescription: isVisible(
      model.buildingInvestmentClassTypeDescription
    ),
    isVisibleAmenities: isVisible(model.amenities),
    isVisiblePropertyConstructionTypeId: isVisible(
      model.propertyConstructionTypeId
    ),
    isVisiblePropertyConstructionTypeDescription: isVisible(
      model.propertyConstructionTypeDescription
    ),
    isVisibleImprovementsConstructionClassTypeId: isVisible(
      model.improvementsConstructionClassTypeId
    ),
    isVisibleImprovementsConstructionClassTypeDescription: isVisible(
      model.improvementsConstructionClassTypeDescription
    ),
    isVisibleEffectiveAgeYears: isVisible(model.effectiveAgeYears),
    isVisibleExteriorWalls: isVisible(model.exteriorWalls),
    isVisiblePropertyConstructionQualityTypeId: isVisible(
      model.propertyConstructionQualityTypeId
    ),
    isVisiblePropertyConstructionQualityTypeDescription: isVisible(
      model.propertyConstructionQualityTypeDescription
    ),
    isVisibleConstructionDescription: isVisible(model.constructionDescription),
    isVisibleHVACComments: isVisible(model.hvacComments),
    isVisibleHVACType: isVisible(model.hvacType),
    isVisibleHVACPercent: isVisible(model.hvacPercent),
    isVisibleRoofType: isVisible(model.roofType),
    isVisibleMajorTenants: isVisible(model.majorTenants),
    isVisibleUsableUnitDensityLargeUOM: isVisible(
      model.usableUnitDensityLargeUOM
    ),
    isVisiblePropertyAminityTypes: isVisible(model.propertyAminityTypes),
    isVisiblePropertyUnitAminityTypes: isVisible(
      model.propertyUnitAminityTypes
    ),
    isVisibleLoadFactor: isVisible(model.loadFactor),
    isVisibleEconomicLife: isVisible(model.economicLife),
    isVisibleFoundation: isVisible(model.foundation),
    isVisibleInteriorFinishOther: isVisible(model.interiorFinishOther),
    isVisibleNumberCoveredParkingSpaces: isVisible(
      model.numberCoveredParkingSpaces
    ),
    isVisibleNumberOpenParkingSpaces: isVisible(model.numberOpenParkingSpaces),
    isVisibleNumberGarageParkingSpaces: isVisible(
      model.numberGarageParkingSpaces
    ),
    isVisiblePropertyAmenitiesScoreId: isVisible(
      model.propertyAmenitiesScoreId
    ),
    isVisiblePropertyAmenitiesScoreDescription: isVisible(
      model.propertyAmenitiesScoreDescription
    ),
    isVisibleUnitAmenities: isVisible(model.unitAmenities),
    isVisibleUnitAmenitiesScoreId: isVisible(model.unitAmenitiesScoreId),
    isVisibleUnitAmenitiesScoreDescription: isVisible(
      model.unitAmenitiesScoreDescription
    ),
    isVisibleBasement: isVisible(model.basement),
    isVisibleWindows: isVisible(model.windows),
    isVisibleSpecialFeatures: isVisible(model.specialFeatures),
    isVisibleBuildingShape: isVisible(model.buildingShape),
    isVisibleInteriorFinishFloors: isVisible(model.interiorFinishFloors),
    isVisibleInteriorFinishWalls: isVisible(model.interiorFinishWalls),
    isVisibleInteriorFinishCeilings: isVisible(model.interiorFinishCeilings),
    isVisibleInteriorFinishLighting: isVisible(model.interiorFinishLighting),
    isVisibleInteriorFinishElectrical: isVisible(
      model.interiorFinishElectrical
    ),
    isVisibleInteriorFinishPlumbing: isVisible(model.interiorFinishPlumbing),
    isVisibleUtilityMeters: isVisible(model.utilityMeters),
    isVisibleRestrooms: isVisible(model.restrooms),
    isVisibleActualAge: isVisible(model.actualAge),
    isVisibleRemainingEconomicLife: isVisible(model.remainingEconomicLife),
    isVisiblePercentDepreciation: isVisible(model.percentDepreciation),
    isVisibleEstGroundFloorAreaSmallUOM: isVisible(
      model.estGroundFloorAreaSmallUOM
    ),
    isVisibleFloorAreaRatio: isVisible(model.floorAreaRatio),
    isVisibleParkingRatioPerUnit: isVisible(model.parkingRatioPerUnit),
    isVisiblePropertyBuildingCertificationTypes: isVisible(
      model.propertyBuildingCertificationTypes
    ),
    isVisibleEnergyUseIntensity: isVisible(model.energyUseIntensity),
    isVisibleEnergyStarRating: isVisible(model.energyStarRating),
  };
}

export function setPropertyEventVisibility(
  model: ReportPropertyEventModel | null
): ReportPropertyEventModel | null {
  if (model === null) {
    return null;
  }
  return {
    ...model,
    isVisiblePropertyTypeDescription: isVisible(model.propertyTypeDescription),
    isVisiblePropertySubtypeDescription: isVisible(
      model.propertySubtypeDescription
    ),
    isVisiblePropertyUseTypeDescription: isVisible(
      model.propertyUseTypeDescription
    ),
    isVisiblePropertyUOMTypeDescription: isVisible(
      model.propertyUOMTypeDescription
    ),
    isVisibleMajorMarketAreaTypeDescription: isVisible(
      model.majorMarketAreaTypeDescription
    ),
    isVisibleSubmarketTypeDescription: isVisible(
      model.submarketTypeDescription
    ),
    isVisibleMarketComments: isVisible(model.marketComments),
    isVisibleCurrencyTypeDescription: isVisible(model.currencyTypeDescription),
    isVisiblePropertyEventTypeDescription: isVisible(
      model.propertyEventTypeDescription
    ),
    isVisibleDistanceFromHospital: isVisible(model.distanceFromHospital),
    isVisibleLicenseCapacity: isVisible(model.licenseCapacity),
    isVisibleStarRatingOverallDescription: isVisible(
      model.starRatingOverallDescription
    ),
    isVisibleUmbrellaCompany: isVisible(model.umbrellaCompany),
    isVisibleServiceOrientationDescription: isVisible(
      model.serviceOrientationDescription
    ),
    isVisibleMarketOrientationDescription: isVisible(
      model.marketOrientationDescription
    ),
    isVisibleMarketMixCommercialPercent: isVisible(
      model.marketMixCommercialPercent
    ),
    isVisibleMarketMixGroupPercent: isVisible(model.marketMixGroupPercent),
    isVisibleMarketMixLeisurePercent: isVisible(model.marketMixLeisurePercent),
    isVisibleMarketMixExtendedStayPercent: isVisible(
      model.marketMixExtendedStayPercent
    ),
    isVisibleVerificationTypeDescription: isVisible(
      model.verificationTypeDescription
    ),
    isVisibleVerificationSource: isVisible(model.verificationSource),
    isVisiblePropertyUpDateTypeDescription: isVisible(
      model.propertyUpDateTypeDescription
    ),
    isVisiblePropertyEventDate: isVisible(model.propertyEventDate),
    isVisibleComments: isVisible(model.comments),
    isVisibleEventComments: isVisible(model.eventComments),
    isVisibleInternalComments: isVisible(model.internalComments),
    isVisiblePublishToMaster: isVisible(model.publishToMaster),
    isVisibleLastSynchronized: isVisible(model.lastSynchronized),
    isVisibleIsConfidential: isVisible(model.isConfidential),
    isVisibleIsInactive: isVisible(model.isInactive),
    isVisiblePropertyLocationEvent: isVisible(model.propertyLocationEvent),
    isVisiblePropertyAddressTypeId: isVisible(model.propertyAddressTypeId),
    isVisiblePropertyAddressTypeDescription: isVisible(
      model.propertyAddressTypeDescription
    ),
    isVisibleName: isVisible(model.name),
    isVisibleFullAddress: isVisible(model.fullAddress),
    isVisibleAddress1: isVisible(model.address1),
    isVisibleAddress2: isVisible(model.address2),
    isVisibleCity: isVisible(model.city),
    isVisibleState: isVisible(model.state),
    isVisibleCounty: isVisible(model.county),
    isVisibleZip: isVisible(model.zip),
    isVisibleCountryId: isVisible(model.countryId),
    isVisibleCountry: isVisible(model.country),
    isVisiblePostDirection: isVisible(model.postDirection),
    isVisiblePreDirection: isVisible(model.preDirection),
    isVisibleStreetName: isVisible(model.streetName),
    isVisibleStreetNumber: isVisible(model.streetNumber),
    isVisibleStreetSuffix: isVisible(model.streetSuffix),
    isVisibleLatitude: isVisible(model.latitude),
    isVisibleLongitude: isVisible(model.longitude),
    isVisibleLocation: isVisible(model.location),
    isVisibleRooftopLatitude: isVisible(model.rooftopLatitude),
    isVisibleRooftopLongitude: isVisible(model.rooftopLongitude),
    isVisibleStreetFrontLatitude: isVisible(model.streetFrontLatitude),
    isVisibleStreetFrontLongitude: isVisible(model.streetFrontLongitude),
    isVisibleMetropolitanStatisticalArea: isVisible(
      model.metropolitanStatisticalArea
    ),
    isVisibleAssessorsParcelNumber: isVisible(model.assessorsParcelNumber),
    isVisiblePrimaryPhoto: isVisible(model.primaryPhoto),
    isVisibleIsGeoManuallyMaintained: isVisible(model.isGeoManuallyMaintained),
    isVisibleIsAddressManuallyMaintained: isVisible(
      model.isAddressManuallyMaintained
    ),
  };
}

export function setImprovementLeaseDetailVisibility(
  model: ReportPropertyImprovementLeaseDetailEventModel | null
): ReportPropertyImprovementLeaseDetailEventModel | null {
  if (model === null) {
    return null;
  }
  return {
    ...model,
    isVisiblePropertyOwnerTenantStatusTypeId: isVisible(
      model.propertyOwnerTenantStatusTypeId
    ),
    isVisiblePropertyOwnerTenantStatusTypeDescription: isVisible(
      model.propertyOwnerTenantStatusTypeDescription
    ),
    isVisibleAverageRemainingLeaseTerm: isVisible(
      model.averageRemainingLeaseTerm
    ),
    isVisiblePropertyOccupancyTypeId: isVisible(model.propertyOccupancyTypeId),
    isVisiblePropertyOccupancyTypeDescription: isVisible(
      model.propertyOccupancyTypeDescription
    ),
    isVisiblePropertyOccupantTypeId: isVisible(model.propertyOccupantTypeId),
    isVisiblePropertyOccupantTypeDescription: isVisible(
      model.propertyOccupantTypeDescription
    ),
    isVisibleOwnerOccupiedPercent: isVisible(model.ownerOccupiedPercent),
    isVisibleRentControlled: isVisibleBoolean(model.rentControlled),
    isVisibleRentSubsidized: isVisibleBoolean(model.rentSubsidized),
    isVisibleTenantPaidUtilities: isVisible(model.tenantPaidUtilities),
    isVisiblePropertyTenantPaidUtilityTypes: isVisible(
      model.propertyTenantPaidUtilityTypes
    ),
    isVisibleTenantSizeRange: isVisible(model.tenantSizeRange),
    isVisibleNumberOfTenants: isVisible(model.numberOfTenants),
  };
}

export function setIndustrialBuildingDetailVisibility(
  model: ReportPropertyIndustrialBuildingDetailEventModel | null
): ReportPropertyIndustrialBuildingDetailEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    isVisiblePercentOffice: isVisible(model.percentOffice),
    isVisibleNumberOfTruckDoors: isVisible(model.numberOfTruckDoors),
    isVisibleDockHighDoors: isVisible(model.dockHighDoors),
    isVisibleRailDoors: isVisible(model.railDoors),
    isVisibleTotalLoadingDoors: isVisible(model.totalLoadingDoors),
    isVisibleRatioOfSmallUOMPerLoadingDoor: isVisible(
      model.ratioOfSmallUOMPerLoadingDoor
    ),
    isVisibleColumnSpacing: isVisible(model.columnSpacing),
    isVisiblePropertyCrossDock: isVisibleBoolean(model.propertyCrossDock),
    isVisibleColdStorageSmallUOM: isVisible(model.coldStorageSmallUOM),
    isVisibleColdStoragePercentage: isVisible(model.coldStoragePercentage),
    isVisibleCoolerSmallUOM: isVisible(model.coolerSmallUOM),
    isVisibleCoolerPercentage: isVisible(model.coolerPercentage),
    isVisibleFreeszerSmallUOM: isVisible(model.freeszerSmallUOM),
    isVisibleFreezerPercentage: isVisible(model.freezerPercentage),
    isVisiblePropertyRailAccess: isVisibleBoolean(model.propertyRailAccess),
    isVisibleClearHeight: isVisible(model.clearHeight),
    isVisiblePercentLaboratory: isVisible(model.percentLaboratory),
  };
}

export function setSaleOperationsVisibility(
  model: ReportPropertySaleOperationsAtDateOfSaleEventModel | null
): ReportPropertySaleOperationsAtDateOfSaleEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    isVisibleOccupancyAtSale: isVisible(model.occupancyAtSale),   
    isVisibleNetIncomeMultiplier: isVisible(model.netIncomeMultiplier),
    isVisibleFinancialsDataSource: isVisible(model.financialsDataSource),
    isVisibleReservesIncluded: isVisible(model.reservesIncluded),
    isVisibleManagementIncluded: isVisible(model.managementIncluded),
    isVisibleDerivedEGIM: isVisible(model.derivedEGIM),
    isVisibleMultipleEBitDA: isVisible(model.multipleEBitDA),
    // isVisibleApproximateOccupiedRooms: isVisible(
    //   model.approximateOccupiedRooms
    // ),
    // isVisibleActualOperatingDataPeriodTypeId: isVisible(
    //   model.actualOperatingDataPeriodTypeId
    // ),
    // isVisibleActualOperatingDataPeriodTypeDescription: isVisible(
    //   model.actualOperatingDataPeriodTypeDescription
    // ),
    isVisibleActualPGI: isVisible(model.actualPGI),
    isVisibleActualPGIPerSmallUOM: isVisible(model.actualPGIPerSmallUOM),
    isVisibleActualPGIPerUnit: isVisible(model.actualPGIPerUnit),
    // isVisibleActualOccupanyPercent: isVisible(model.actualOccupanyPercent),
    isVisibleActualOtherIncome: isVisible(model.actualOtherIncome),
    isVisibleActualOtherIncomePerSmallUOM: isVisible(
      model.actualOtherIncomePerSmallUOM
    ),
    isVisibleActualOtherIncomePerUnit: isVisible(
      model.actualOtherIncomePerUnit
    ),
    isVisibleActualEGI: isVisible(model.actualEGI),
    isVisibleActualEGIPerSmallUOM: isVisible(model.actualEGIPerSmallUOM),
    isVisibleActualEGIPerUnit: isVisible(model.actualEGIPerUnit),
    isVisibleActualEGIMDerived: isVisible(model.actualEGIMDerived),
    isVisibleActualOperatingExpense: isVisible(model.actualOperatingExpense),
    isVisibleActualOperatingExpensePerSmallUOM: isVisible(
      model.actualOperatingExpensePerSmallUOM
    ),
    isVisibleActualOperatingExpensePerUnit: isVisible(
      model.actualOperatingExpensePerUnit
    ),
    isVisibleActualOperatingExpenseRationPercent: isVisible(
      model.actualOperatingExpenseRationPercent
    ),
    isVisibleActualNOI: isVisible(model.actualNOI),
    isVisibleActualNOIPerSmallUOM: isVisible(model.actualNOIPerSmallUOM),
    isVisibleActualNOIPerUnit: isVisible(model.actualNOIPerUnit),
    isVisibleActualCapRateDerivedPercent: isVisible(
      model.actualCapRateDerivedPercent
    ),
    isVisibleProFormaStabOperatingDataPeriodTypeId: isVisible(
      model.proFormaStabOperatingDataPeriodTypeId
    ),
    isVisibleProFormaStabOperatingDataPeriodTypeDescription: isVisible(
      model.proFormaStabOperatingDataPeriodTypeDescription
    ),
    isVisibleProFormaStabPGI: isVisible(model.proFormaStabPGI),
    isVisibleProFormaStabPGIPerUOM: isVisible(model.proFormaStabPGIPerUOM),
    isVisibleProFormaStabPGIPerUnit: isVisible(model.proFormaStabPGIPerUnit),
    isVisibleProFormaStabOcc: isVisible(model.proFormaStabOcc),
    isVisibleProFormaStabOtherIncome: isVisible(model.proFormaStabOtherIncome),
    isVisibleProFormaStabOtherIncomePerSmallUOM: isVisible(
      model.proFormaStabOtherIncomePerSmallUOM
    ),
    isVisibleProFormaStabOtherIncomePerUnit: isVisible(
      model.proFormaStabOtherIncomePerUnit
    ),
    isVisibleProFormaStabEGI: isVisible(model.proFormaStabEGI),
    isVisibleProFormaStabEGIPerSmallUOM: isVisible(
      model.proFormaStabEGIPerSmallUOM
    ),
    isVisibleProFormaStabEGIPerUnit: isVisible(model.proFormaStabEGIPerUnit),
    isVisibleProFormaStabOPExp: isVisible(model.proFormaStabOPExp),
    isVisibleProFormaStabOPExpPerSmallUOM: isVisible(
      model.proFormaStabOPExpPerSmallUOM
    ),
    isVisibleProFormaStabOPExpPerUnit: isVisible(
      model.proFormaStabOPExpPerUnit
    ),
    isVisibleProFormaStabOPExpRatio: isVisible(model.proFormaStabOPExpRatio),
    isVisibleProFormaStabNOI: isVisible(model.proFormaStabNOI),
    isVisibleProFormaStabNOIPerSmallUOM: isVisible(
      model.proFormaStabNOIPerSmallUOM
    ),
    isVisibleProFormaStabNOIPerUnit: isVisible(model.proFormaStabNOIPerUnit),
    isVisibleCapRateDerivedStabilized: isVisible(
      model.capRateDerivedStabilized
    ),
    isVisibleCapRateReported: isVisible(model.capRateReported),
    isVisibleReportedCapRatePremise: isVisible(model.reportedCapRatePremise),
    isVisibleReportedDiscountRate: isVisible(model.reportedDiscountRate),
    isVisibleReportedReversionCapRate: isVisible(
      model.reportedReversionCapRate
    ),
    // isVisiblePricePSFMallShopGLA: isVisible(model.pricePSFMallShopGLA),
    // isVisibleComparableStoreSalesMallShop: isVisible(
    //   model.comparableStoreSalesMallShop
    // ),
    isVisibleAverageDailyRoomRate: isVisible(model.averageDailyRoomRate),
    isVisibleRevenuePerAvailableRoom: isVisible(model.revenuePerAvailableRoom),
    isVisibleRoomRevenueMultiplier: isVisible(model.roomRevenueMultiplier),
    // isVisibleRoomRevenue: isVisible(model.roomRevenue),
    // isVisibleMarketingTimeDays: isVisible(model.marketingTimeDays),
    // isVisiblePropertyOperationsStatusTypeId: isVisible(
    //   model.propertyOperationsStatusTypeId
    // ),
    isVisiblePropertyOperationsStatusTypeDescription: isVisible(
      model.propertyOperationsStatusTypeDescription
    ),
    isVisibleRemainingLeaseTerm: isVisible(model.remainingLeaseTerm),
  };
}

export function setSaleEventVisibility(
  model: ReportPropertySaleEventModel | null
): ReportPropertySaleEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    isVisibleSpecialtySaleTypeId: isVisible(model.specialtySaleTypeId),
    isVisibleSpecialtySaleTypeDescription: isVisible(
      model.specialtySaleTypeDescription
    ),
    isVisibleTransactionTypeId: isVisible(model.transactionTypeId),
    isVisibleTransactionTypeDescription: isVisible(
      model.transactionTypeDescription
    ),
    isVisibleSaleStatusTypeId: isVisible(model.saleStatusTypeId),
    isVisibleSaleStatusTypeDescription: isVisible(
      model.saleStatusTypeDescription
    ),
    isVisiblePropertyRightsTypeId: isVisible(model.propertyRightsTypeId),
    isVisiblePropertyRightsTypeDescription: isVisible(
      model.propertyRightsTypeDescription
    ),
    isVisibleSaleDate: isVisible(model.saleDate),
    isVisibleGrantor: isVisible(model.grantor),
    isVisibleGrantee: isVisible(model.grantee),
    isVisibleSalePrice: isVisible(model.salePrice),
    isVisibleDocumentType: isVisible(model.documentType),
    isVisibleRecordingNumber: isVisible(model.recordingNumber),
    isVisibleFinancingTypeId: isVisible(model.financingTypeId),
    isVisibleFinancingTermsAdjustment: isVisible(
      model.financingTermsAdjustment
    ),
    isVisibleFinancingTypeDescription: isVisible(
      model.financingTypeDescription
    ),
    isVisibleConditionsOfSaleAdjustment: isVisible(
      model.conditionsOfSaleAdjustment
    ),
    isVisibleOtherAdjustment: isVisible(model.otherAdjustment),
    isVisiblePricePerUnit: isVisible(model.pricePerUnit),
    isVisibleEffectivePricePerUnit: isVisible(model.effectivePricePerUnit),
    isVisiblePotentialValueFAR: isVisible(model.potentialValueFAR),
    isVisibleRecordingDate: isVisible(model.recordingDate),
    isVisibleContractDate: isVisible(model.contractDate),
    isVisibleListingPrice: isVisible(model.listingPrice),
    isVisibleListingDate: isVisible(model.listingDate),
    isVisiblePricePerSmallUOMGBA: isVisible(model.pricePerSmallUOMGBA),
    isVisiblePricePerSmallUOMNRA: isVisible(model.pricePerSmallUOMNRA),
    isVisibleUsableValueLandPerSmallUOM: isVisible(
      model.usableValueLandPerSmallUOM
    ),
    isVisibleUsableValueLandPerLargeUOM: isVisible(
      model.usableValueLandPerLargeUOM
    ),
    isVisibleGrossValueLandPerSmallUOM: isVisible(
      model.grossValueLandPerSmallUOM
    ),
    isVisibleGrossValueLandPerLargeUOM: isVisible(
      model.grossValueLandPerLargeUOM
    ),
    isVisibleEffectiveSalePrice: isVisible(model.effectiveSalePrice),
  };
}

export function setLeaseDetailVisibility(
  model: ReportPropertyLeaseDetailEventModel
): ReportPropertyLeaseDetailEventModel {
  if(model === null)
    return model;
else{
  return {
    ...model,
    isVisiblePropertyCommercialSpaceEventId: isVisible(
      model.propertyCommercialSpaceEventId
    ),
    isVisibleActive: isVisible(model.active),
    isVisibleRentableArea: isVisible(model.rentableArea),
    isVisibleAlternateArea: isVisible(model.alternateArea),
    isVisibleFullBuildingLease: isVisibleBoolean(model.fullBuildingLease),
    isVisiblePropertySpaceTypeId: isVisible(model.propertySpaceTypeId),
    isVisiblePropertySpaceTypeDescription: isVisible(
      model.propertySpaceTypeDescription
    ),
    isVisibleSuiteSpaceNumber: isVisible(model.suiteSpaceNumber),
    isVisibleNumberParkingSpaces: isVisible(model.numberParkingSpaces),
    isVisiblePropertyLeaseStatusTypeId: isVisible(
      model.propertyLeaseStatusTypeId
    ),
    isVisiblePropertyLeaseStatusTypeDescription: isVisible(
      model.propertyLeaseStatusTypeDescription
    ),
    isVisibleLeaseSignedDate: isVisible(model.leaseSignedDate),
    isVisibleLeaseStartAvailableDate: isVisible(model.leaseStartAvailableDate),
    isVisibleLeaseExpirationDate: isVisible(model.leaseExpirationDate),
    isVisibleLeaseTerm: isVisible(model.leaseTerm),
    isVisibleLessor: isVisible(model.lessor),
    isVisibleLessee: isVisible(model.lessee),
    isVisiblePropertyLesseeTypeId: isVisible(model.propertyLesseeTypeId),
    isVisiblePropertyLesseeTypeDescription: isVisible(
      model.propertyLesseeTypeDescription
    ),
    isVisibleTenantCreditRating: isVisible(model.tenantCreditRating),
    isVisiblePropertyCreditRatingSourceTypeId: isVisible(
      model.propertyCreditRatingSourceTypeId
    ),
    isVisiblePropertyCreditRatingSourceTypeDescription: isVisible(
      model.propertyCreditRatingSourceTypeDescription
    ),
    isVisibleTypicalCeilingHeight: isVisible(model.typicalCeilingHeight),
    isVisibleRatingDate: isVisible(model.ratingDate),
    isVisibleOfficePercentage: isVisible(model.officePercentage),
    isVisibleNumberLoadingDoors: isVisible(model.numberLoadingDoors),
    isVisiblePropertyMeasureTypeId: isVisible(model.propertyMeasureTypeId),
    isVisiblePropertyMeasureTypeDescription: isVisible(
      model.propertyMeasureTypeDescription
    ),
    isVisibleFaceRentalRate: isVisible(model.faceRentalRate),
    isVisibleEffectiveRentalRate: isVisible(model.effectiveRentalRate),
    isVisiblePropertyBaseRentEscalationTypeId: isVisible(
      model.propertyBaseRentEscalationTypeId
    ),
    isVisiblePropertyBaseRentEscalationTypeDescription: isVisible(
      model.propertyBaseRentEscalationTypeDescription
    ),
    isVisibleEscalationDescription: isVisible(model.escalationDescription),
    isVisiblePercentageRent: isVisible(model.percentageRent),
    isVisiblePropertyLeaseReimbursementMethodTypeId: isVisible(
      model.propertyLeaseReimbursementMethodTypeId
    ),
    isVisiblePropertyLeaseReimbursementMethodTypeDescription: isVisible(
      model.propertyLeaseReimbursementMethodTypeDescription
    ),
    isVisibleLeaseReimbursementDescription: isVisible(
      model.leaseReimbursementDescription
    ),
    isVisibleExpenseStopDescription: isVisible(model.expenseStopDescription),
    isVisiblePropertyLandlordExpenseStop: isVisibleBoolean(
      model.propertyLandlordExpenseStop
    ),
    isVisiblePropertyTenantExpenseCap: isVisibleBoolean(
      model.propertyTenantExpenseCap
    ),
    isVisibleExpenseCapDescription: isVisible(model.expenseCapDescription),
    isVisibleEstimatedAnnnualPropertyExpensePerSmallUOM: isVisible(
      model.estimatedAnnnualPropertyExpensePerSmallUOM
    ),
    isVisibleLandlordPaidExpenses: isVisible(model.landlordPaidExpenses),
    isVisibleTenantPaidExpenses: isVisible(model.tenantPaidExpenses),
    isVisibleRenewalOptionsDescription: isVisible(
      model.renewalOptionsDescription
    ),
    isVisibleConcessions: isVisible(model.concessions),
    isVisibleFreeRentMonth: isVisible(model.freeRentMonth),
    isVisibleTenantImprovementAllowancePerSmallUOM: isVisible(
      model.tenantImprovementAllowancePerSmallUOM
    ),
    isVisiblePropertyTenantImprovementTypeId: isVisible(
      model.propertyTenantImprovementTypeId
    ),
    isVisiblePropertyTenantImprovementTypeDescription: isVisible(
      model.propertyTenantImprovementTypeDescription
    ),
    isVisibleHVACPercent: isVisible(model.hvacPercent),
    isVisibleRenewalOptionsIncluded: isVisibleBoolean(model.renewalOptionsIncluded),
    isVisiblePropertyCommercialSpaceTypeId: isVisible(
      model.propertyCommercialSpaceTypeId
    ),
    isVisiblePropertyCommercialSpaceTypeDescription: isVisible(
      model.propertyCommercialSpaceTypeDescription
    ),
    isVisibleComments: isVisible(model.comments),
  };
}
}

export function setRentalSurveyVisibility(
  model: ReportPropertyRentalSurveyEventModel | null
): ReportPropertyRentalSurveyEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    isVisibleSurveyDate: isVisible(model.surveyDate),
    isVisibleLeasingAgentName: isVisible(model.leasingAgentName),
    isVisibleCompany: isVisible(model.company),
    isVisibleVacancyAtSurveyPercentage: isVisible(
      model.vacancyAtSurveyPercentage
    ),
  };
}

export function applySalesConfidential(
  model: ReportSaleEventModel
): ReportSaleEventModel {
  return {
    ...model,
    reportPropertyEventModel: setPropertyEventItemsConfidential(
      model.reportPropertyEventModel
    ),
    reportPropertyCommercialSpaceModels: setCommercialSpaceItemsConfidential(
      model.reportPropertyCommercialSpaceModels
    ),
    reportPropertySaleEventModel: setSaleEventConfidential(
      model.reportPropertySaleEventModel
    ),
  };
}

export function applyRentalConfidential(
  model: ReportRentalSurveyEventModel
): ReportRentalSurveyEventModel {
  return {
    ...model,
    reportPropertyEventModel: setPropertyEventItemsConfidential(
      model.reportPropertyEventModel
    ),
    reportPropertyCommercialSpaceModels: setCommercialSpaceItemsConfidential(
      model.reportPropertyCommercialSpaceModels
    ),
    reportPropertyRentalSurveyEventModel: setRentalSurveyEventConfidential(
      model.reportPropertyRentalSurveyEventModel
    ),
    reportPropertyLeaseDetailEventModels: setLeaseDetailEventConfidential(
      model.reportPropertyLeaseDetailEventModels
    ),
  };
}
export function applyIEConfidential(
  model: ReportIncomeAndExpenseEventModel
): ReportIncomeAndExpenseEventModel {
  return {
    ...model,
    reportPropertyEventModel: setPropertyEventItemsConfidential(
      model.reportPropertyEventModel
    ),
    reportPropertyCommercialSpaceModels: setCommercialSpaceItemsConfidential(
      model.reportPropertyCommercialSpaceModels
    ),
  };
}

function setSaleEventConfidential(
  model: ReportPropertySaleEventModel | null
): ReportPropertySaleEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    grantor: model.isVisibleGrantor ? confidential : model.grantor,
    grantee: model.isVisibleGrantee ? confidential : model.grantee,
  };
}

function setRentalSurveyEventConfidential(
  model: ReportPropertyRentalSurveyEventModel | null
): ReportPropertyRentalSurveyEventModel | null {
  if(model === null)
    return null;
  
  return {
    ...model,
    leasingAgentName: model.isVisibleLeasingAgentName
      ? confidential
      : model.leasingAgentName,
  };
}

function setLeaseDetailEventConfidential(
  leaseModels: ReportPropertyLeaseDetailEventModel[]
): ReportPropertyLeaseDetailEventModel[] {
  return leaseModels.map((model) => {
    return {
      ...model,
      lessee: model.isVisibleLessee ? confidential : model.lessee,
      lessor: model.isVisibleLessor ? confidential : model.lessor,
      comments: "",
      isVisibleComments: false
    };
  });
}

function setPropertyEventItemsConfidential(
  model: ReportPropertyEventModel |  null
): ReportPropertyEventModel | null {
  if(model === null)
    return null;

  return {
    ...model,
    name: model.isVisibleName ? confidential : model.name,
    county: model.isVisibleCounty ? confidential : model.county,
    majorMarketAreaTypeDescription:
      model.isVisibleMajorMarketAreaTypeDescription
        ? confidential
        : model.majorMarketAreaTypeDescription,
    submarketTypeDescription: model.isVisibleSubmarketTypeDescription
      ? confidential
      : model.submarketTypeDescription,
    assessorsParcelNumber: model.isVisibleAssessorsParcelNumber
      ? confidential
      : model.assessorsParcelNumber,
    verificationSource: model.isVisibleVerificationSource
      ? confidential
      : model.verificationSource,
    address1: model.isVisibleAddress1
      ? !model.isVisibleCity && !model.isVisibleState
        ? confidential
        : ""
      : model.address1,
    address2: model.isVisibleAddress1 ? "" : model.address2,
    comments: "",
    eventComments: "",
    isVisibleComments: false,
    isVisibleEventComments: false
  };
}

function setCommercialSpaceItemsConfidential(
  commercialModels: ReportPropertyCommercialSpaceModel[] | null 
): ReportPropertyCommercialSpaceModel[] | null {
  if(commercialModels === null)
    return null;

  return commercialModels.map((model) => {
    return {
      ...model,
      tenantName: model.tenantName?.trim() ? confidential : model.tenantName,
    };
  });
}