import { ReportPropertyEventModel } from "dto/reports/shared/reportPropertyEvent";
import { Row } from "reactstrap";

const PropertyAndEventComments = ({ data }: { data: ReportPropertyEventModel | null }) => {
    if (data === null)
        return;
    let propertyEventModel: ReportPropertyEventModel = data;
    return (<>
        <Row>
            <div className="d-inline p-1 mt-1 heading-bg-primary">
                <h5 className="title ps-2 p-1 mb-1 fw-bold">Comments</h5>
            </div>
        </Row>
        <Row>
            {propertyEventModel.isVisibleComments &&
                <span>{propertyEventModel.comments}</span>
            }
            {(propertyEventModel.isVisibleComments && propertyEventModel.isVisibleEventComments) ?
                <span><br /><br />{propertyEventModel.eventComments}</span>
                : propertyEventModel.isVisibleEventComments ? <span>{propertyEventModel.eventComments}</span> : ''
            }
        </Row>
    </>);
}


export default PropertyAndEventComments;