import { ReportIncomeAndExpenseEventModel } from "dto/reports/reportIncomeExpenseEvent";
import { ReportPropertyImprovementDetailEventModel } from "dto/reports/shared/reportPropertyImprovementDetailEvent";
import { ReportPropertyIncomeAndExpenseSummaryEventModel } from "dto/reports/shared/reportPropertyIncomeAndExpenseSummaryEvent";

const lineItemsIds = [1, 2, 3, 4, 6, 7, 8];
const displayItemsIds = [5];
const displayLineItemGLAccountIds = [159]; //Operating Expense Ratio


function hideReportLineItems(
  models: ReportPropertyIncomeAndExpenseSummaryEventModel[] | null
): ReportPropertyIncomeAndExpenseSummaryEventModel[] | null {
  if(models === null)
    return null;

  return models.map((model) => {
    let isHidden = model.isHidden;

    // Show all rows when editable
    if (model.editable) {
      isHidden = null;
    } else {
      // These rules are only for the "All Income and Expense" Models
      if (
        model.glAccountId &&
        displayLineItemGLAccountIds.includes(model.glAccountId)
      ) {
        isHidden = null;
      }

      if (
        (isHidden === null || isHidden === undefined) &&
        (model.amount === null || model.amount === undefined) &&
        model.glAccountLineItemTypeId &&
        lineItemsIds.includes(model.glAccountLineItemTypeId)
      ) {
        isHidden = true;
      }

      // Hide Header or Blank LineItem when AssociatedHeaderGLAccountId.Amount is null
      if (
        model.associatedHeaderGLAccountId &&
        displayItemsIds.includes(model.glAccountLineItemTypeId || 0)
      ) {
        const associatedModel = models.find(
          (m) => m.glAccountId === model.associatedHeaderGLAccountId
        );
        if (associatedModel && (associatedModel.amount === undefined || associatedModel.amount === null)) {
          isHidden = true;
        }
      }
    }

    return {
      ...model,
      isHidden,
    };
  });
}

function hideReportColumns(
  models: ReportPropertyIncomeAndExpenseSummaryEventModel[] | null,
  improvementDetailModel: ReportPropertyImprovementDetailEventModel | null
): ReportPropertyIncomeAndExpenseSummaryEventModel[] | null {
  if(models === null)
    return null;

  return models.map((model) => {
    const rentableAreaSmallUOM =
    improvementDetailModel?.rentableAreaSmallUOM || 0;
    const numberOfDemisedUnits =
    improvementDetailModel?.numberOfDemisedUnits || 0;

    if (rentableAreaSmallUOM > 0 && numberOfDemisedUnits > 0) {
      return {
        ...model,
        colSize: 7,
        hideAmountPerUnitCol: false,
        hideAmountPerSFCol: false,
      };
    } else if (rentableAreaSmallUOM === 0 && numberOfDemisedUnits > 0) {
      return {
        ...model,
        colSize: 8,
        hideAmountPerUnitCol: false,
        hideAmountPerSFCol: true,
      };
    } else if (rentableAreaSmallUOM > 0 && numberOfDemisedUnits === 0) {
      return {
        ...model,
        colSize: 8,
        hideAmountPerUnitCol: true,
        hideAmountPerSFCol: false,
      };
    } else if (rentableAreaSmallUOM === 0 && numberOfDemisedUnits === 0) {
      return {
        ...model,
        colSize: 9,
        hideAmountPerUnitCol: true,
        hideAmountPerSFCol: true,
      };
    }

    return model;
  });
}
    
export function PrepReportModel(
  model: ReportIncomeAndExpenseEventModel
): ReportIncomeAndExpenseEventModel {
  let hideLineItemsModels = hideReportLineItems(model.reportPropertyIncomeAndExpenseSummaryEventModels);
  let hideColumnsModels = hideReportColumns(hideLineItemsModels, model.reportPropertyImprovementDetailEventModel);
  return{
    ...model,
    reportPropertyIncomeAndExpenseSummaryEventModels: hideColumnsModels
  };
}
    