import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sale from './sale';
import RentalSurvey from './rentalSurvey';
import IncomeExpense from './incomeExpense';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectReportPropertyEventsSelected } from 'slices/reports/reportPropertyEventsSlice';
import { getReportDataSheet } from 'components/api/reportsData';
import { Identity } from 'dto/identity';
import { selectIdentity } from 'slices/user/identitySlice';
import Loading from '../../loading';
import { Col, Container, Row } from 'reactstrap';
import { useReactToPrint } from 'react-to-print';
import "./dataSheet.scss";
import PropertyEventTypeEnum from 'enums/propertyEventType';
import { ReportPropertyEvents } from 'dto/reports/reportPropertyEvents';

type GenericProps<T extends React.HTMLAttributes<HTMLElement>> = T;
export default function DataSheet<
    T extends React.HTMLAttributes<HTMLElement>
>(props: GenericProps<T>) {
    const params = useParams();
    const jobAssignmentPropertyId = parseInt(params.jobAssignmentPropertyId || '');
    const compTypeParam = params.compType || '';
    const identity: Identity = useAppSelector(selectIdentity);
    const dispatch = useAppDispatch();
    const reportPropertyEvents = useAppSelector(selectReportPropertyEventsSelected);
    const [loading, setLoading] = useState(true);   
    const compTypeId = useMemo(() => getCompTypeId(compTypeParam), [compTypeParam]);

    function getCompTypeId(compTypeParam: string): number {
        let compTypeId: number;
        switch (compTypeParam) {
            case 'sale': compTypeId = PropertyEventTypeEnum.Sale; break;
            case 'rentalsurvey': compTypeId = PropertyEventTypeEnum.RentalSurvey; break;
            case 'incomeexpense': compTypeId = PropertyEventTypeEnum.IncomeExpense; break;
            default: compTypeId = 0;
        }

        return compTypeId;
    }

    function checkReportPropertyEventsIsNull(): boolean {
        if (reportPropertyEvents.saleEvents === null
            && reportPropertyEvents.rentalEvents === null
            && reportPropertyEvents.incomeAndExpenseEvents === null)
            return true;
        else
            return false;
    }
    useEffect(() => {
        if (checkReportPropertyEventsIsNull()) {
            setLoading(true);            
            getReportDataSheet(identity, jobAssignmentPropertyId, compTypeId)
                .then(function (data: ReportPropertyEvents) {
                    return dispatch({
                        type: "reportPropertyEvents/load",
                        payload: data
                    });
                }).catch((err) => {                    
                    console.error('Error fetching data:', err);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }, [dispatch, identity, jobAssignmentPropertyId, compTypeId]);

    const contentRef = useRef(null);
    const handlePrint = useReactToPrint({
        contentRef
    });
    const onPrintClick = () => {
        if (typeof handlePrint === 'function') {
            handlePrint();
        }
    };
    const sortBySequenceNumber = (a: any, b: any) => {
        if (a.sequenceNumber === null && b.sequenceNumber === null)
            return 0;
        if (a.sequenceNumber === null)
            return -1;
        if (b.sequenceNumber === null)
            return 1;
        return a.sequenceNumber - b.sequenceNumber;
    }

    return (<Container fluid className="h-100 p-0">
        {loading ? (
            <Loading></Loading>
        ) : (
            <>
                <Row className="p-3 mb-2 bg-white text-dark border-bottom">
                    <Col md={6}><h2 className="">View Datasheet </h2></Col>
                    <Col md={4} className="d-flex align-items-center">
                        <span className="print-icon"></span>
                            <button className="bg-white border-0 newmark-color" onClick={onPrintClick}>Print</button>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end"> <button className='close-icon-gray border-0' onClick={() => window.close()}></button></Col>
                </Row>
                    <div className='container-fluid p-4'>
                        <div ref={contentRef} className="p-2">
                        {compTypeId === PropertyEventTypeEnum.Sale &&
                            reportPropertyEvents.saleEvents
                                && Array.isArray(reportPropertyEvents.saleEvents)
                            && reportPropertyEvents.saleEvents.length > 0
                                && [...reportPropertyEvents.saleEvents].sort(sortBySequenceNumber).map((saleEvent, index) => (
                                    <Sale key={`${compTypeParam}-${index}`} data={saleEvent} />
                            ))
                        }

                        {compTypeId === PropertyEventTypeEnum.RentalSurvey &&
                            reportPropertyEvents.rentalEvents
                                && Array.isArray(reportPropertyEvents.rentalEvents)
                            && reportPropertyEvents.rentalEvents.length > 0
                                && [...reportPropertyEvents.rentalEvents].sort(sortBySequenceNumber).map((rentalEvent, index) => (
                                    <RentalSurvey key={`${compTypeParam}-${index}`} data={rentalEvent} />
                            ))
                        }

                        {compTypeId === PropertyEventTypeEnum.IncomeExpense &&
                            reportPropertyEvents.incomeAndExpenseEvents
                                && Array.isArray(reportPropertyEvents.incomeAndExpenseEvents)
                            && reportPropertyEvents.incomeAndExpenseEvents.length > 0
                                && [...reportPropertyEvents.incomeAndExpenseEvents].sort(sortBySequenceNumber).map((incomeAndExpenseEvent, index) => (
                                    <IncomeExpense key={`${compTypeParam}-${index}`} data={incomeAndExpenseEvent} />
                            ))
                            }                              

                    </div>
                </div>
                <Row>
                    <footer className='footer'>
                        <Row className="p-3 bg-white border-top">
                                <Col className="action-icon-parent d-flex align-items-center justify-content-center col-12">
                                    <button type="button" className="action-icon" onClick={() => window.close()}>Done</button></Col>
                        </Row>
                    </footer>

                </Row>
            </>

        )}
    </Container>

    );
}
