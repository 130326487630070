export function dateFormatter(dateAsString: string | null): string {
  if (dateAsString) {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit", // Display the day with leading zeros (e.g., 01)
      month: "2-digit", // Display the month with leading zeros (e.g., 04)
      year: "numeric", // Display the full year (e.g., 2023)
    };
    return new Date(dateAsString).toLocaleDateString(undefined, options);
  }
  return "";
}
