// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Assignment } from "dto/assignment";
import JobAssignmentStatus from "enums/jobAssignmentStatus";
import { RootState } from "store";

const initialState: Assignment[] = [];

export const assignmentListSlice = createSlice({
  name: "assignmentList",
  initialState,
  reducers: {
    load(state, action: PayloadAction<Assignment[]>) {
      const newData = action.payload.filter(
        (item) =>
          !state.some(
            (existingItem) =>
              existingItem.jobAssignmentPropertyId ===
              item.jobAssignmentPropertyId
          )
      );
      state.push(...newData);
    },
    add(state, action: PayloadAction<Assignment>) {
      state.push(action.payload);
    },
    clearAssignmentList(state) {
      while (state.length) {
        state.pop();
      }
    },
  },
});
export const { load, add } = assignmentListSlice.actions;
const selectRoot = (state: RootState) => state;
export const selectAssignmentList = createSelector([selectRoot], function (state: RootState): Assignment[]{
  return state.assignmentList;
});

export default assignmentListSlice.reducer;
