import { getPhotos } from "components/api/reportsData";
import { Identity } from "dto/identity";
import { PropertyStorageEventModel } from "dto/propertyStorageEvent";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { selectIdentity } from "slices/user/identitySlice";
import Loading from "../../loading";
import { useReactToPrint } from "react-to-print";
import "./dataSheet.scss";
import { Assignment } from "dto/assignment";
import { selectAssignmentList } from "slices/assignment/assignmentListSlice";

type GenericProps<T extends React.HTMLAttributes<HTMLElement>> = T;
export default function Photos<
    T extends React.HTMLAttributes<HTMLElement>
>(props: GenericProps<T>) {
    const dispatch = useAppDispatch();
    const params = useParams();
    const propertyEventId: number = parseInt(params.propertyEventId || '');
    const jobAssignmentPropertyId: number = parseInt(params.jobAssignmentPropertyId || '');
    const [loading, setLoading] = useState(true);
    const identity: Identity = useAppSelector(selectIdentity);
    const [storageModel, setStorageModel] = useState<PropertyStorageEventModel[]>([]);
    const contentRef = useRef(null);
    const listOfAssignments: Assignment[] = useAppSelector(selectAssignmentList);
    const propertyAddress = listOfAssignments.find(x => x.propertyEventId === propertyEventId && x.jobAssignmentPropertyId === jobAssignmentPropertyId)?.propertyAddress1;
    const propertyName = listOfAssignments.find(x => x.propertyEventId === propertyEventId && x.jobAssignmentPropertyId === jobAssignmentPropertyId)?.propertyName;

    const handlePrint = useReactToPrint({
        contentRef
    });
    const onPrintClick = () => {
        if (typeof handlePrint === 'function') {
            handlePrint();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getPhotos(identity, jobAssignmentPropertyId, propertyEventId)
                .then(function (data: PropertyStorageEventModel[]) {
                    setStorageModel(data);
                }).catch((err) => {
                    console.error('Error fetching data:', err);
                }).finally(() => {
                    setLoading(false);
                });
        };

        fetchData();
    }, [dispatch, identity, jobAssignmentPropertyId, propertyEventId]);
    return (<Container fluid className="h-100 p-0">
        {loading ? (
            <Loading></Loading>
        ) : (
            <>
                <Row className="p-3 mb-2 bg-white text-dark border-bottom">
                    <Col md={6}><h2 className="">View Photos </h2></Col>
                    <Col md={4} className="d-flex align-items-center">
                        <span className="print-icon"></span>
                        <button className="bg-white border-0 newmark-color" onClick={onPrintClick}>Print</button>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-end"> <button className='close-icon-gray border-0' onClick={() => window.close()}></button></Col>
                </Row>
                <div className='container-fluid p-4 mb-9'>
                    <div ref={contentRef} className="p-2 bg-white border">
                        <div className='col-md-12 p-3'>
                            <div className='row'>
                                <div className='d-inline col-6 p-0 mb-3'>
                                    <h2 className='fw-bold'>Subject Property Photos</h2>
                                </div>
                                <div className='d-flex col-6 p-0 justify-content-end'>
                                    <span className='logo-NKF-report logo-height'></span>
                                </div>
                            </div>
                            {(propertyAddress !== null || propertyName !== null) &&
                                <Row>
                                    <div className="d-inline p-1 heading-bg-primary">
                                        {propertyAddress !== null &&
                                            <h2 className="ps-2">{propertyAddress}</h2>}
                                        {propertyName !== null &&
                                            <h6 className="ps-2">{propertyName}</h6>}
                                    </div>
                                </Row>
                            }
                            {storageModel && Array.isArray(storageModel) && storageModel.slice(0, 30).map((photo, index) => {
                                return index % 2 === 0 ?
                                    (<Row>
                                        <Col md={6} sm={6} xs={6} className="pt-2 print-top">
                                            <div className="card bg-white border-0">
                                                <img className="card-img-top rounded-0 fixed-size-image" src={photo.urlImageCompressed} alt={photo.description} />
                                                <div className="card-body pt-1">
                                                    <p className="card-text">{photo.description} </p>
                                                </div>
                                            </div>
                                        </Col>
                                        {index + 1 < storageModel.length &&
                                            <Col md={6} sm={6} xs={6} className="pt-2 print-top">
                                                <div className="card bg-white border-0">
                                                    <img className="card-img-top rounded-0 fixed-size-image" src={storageModel[index + 1].urlImageCompressed} alt={storageModel[index + 1].description} />
                                                    <div className="card-body pt-1">
                                                        <p className="card-text">{storageModel[index + 1].description} </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        }
                                    </Row>)
                                    : <></>;
                            })}
                        </div>
                    </div>
                </div>
                <Row>
                    <footer className='footer'>
                        <Row className="p-3 bg-white border-top">
                            <Col className="action-icon-parent d-flex align-items-center justify-content-center col-12">
                                <button type="button" className="action-icon" onClick={() => window.close()}>Done</button></Col>
                        </Row>
                    </footer>
                </Row>
            </>
        )
        }
    </Container >);
}
