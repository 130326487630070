import PropertyLandParcelClassificationTypeEnum from 'enums/propertyLandParcelClassificationType';
import { Col, Row } from 'reactstrap';
import { isModelVisible } from 'components/utils/reports/reportVisibility';

import PropertyEventTypeEnum from 'enums/propertyEventType';
import { ReportSaleEventModel } from 'dto/reports/reportSaleEvent';
import { ReportRentalSurveyEventModel } from 'dto/reports/reportRentalSurveyEvent';
import { ReportIncomeAndExpenseEventModel } from 'dto/reports/reportIncomeExpenseEvent';
import { ReportPropertyEventModel } from 'dto/reports/shared/reportPropertyEvent';
import { ReportPropertySiteDetailEventModel } from 'dto/reports/shared/reportPropertySiteDetailEvent';
import { ReportPropertyImprovementDetailEventModel } from 'dto/reports/shared/reportPropertyImprovementDetailEvent';
import { ReportPropertyIndustrialBuildingDetailEventModel } from 'dto/reports/shared/reportPropertyIndustrialBuildingDetailEvent';
import { ReportPropertyImprovementLeaseDetailEventModel } from 'dto/reports/shared/reportPropertyImprovementLeaseDetailEvent';
import { ReportPropertyUnitMixEventModel } from 'dto/reports/shared/reportPropertyUnitMixEvent';
import { ReportPropertyUnitMixTotalEventModel } from 'dto/reports/shared/reportPropertyUnitMixTotalEvent';
import { ReportPropertyLandParcelDetailEventModel } from 'dto/reports/shared/reportPropertyLandParcelDetailEvent';
import { ReportPropertyCommercialSpaceModel } from 'dto/reports/shared/reportPropertyCommercialSpace';
import { ReportPropertyCommercialSpaceTotalEventModel } from 'dto/reports/shared/reportPropertyCommercialSpaceTotalEvent';
import { ReportPropertyLandParcelGroupEventModel } from 'dto/reports/shared/reportPropertyLandParcelGroupEvent';
import { ReportPropertyLandParcelTotalEventModel } from 'dto/reports/shared/reportPropertyLandParcelTotalEvent';
import { formatNumberWithDecimals, formatNumberWithDecimalsWithCurrency, formatNumberWithDecimalsWithPercentage, formatNumberWithNoDecimals, formatValueWithDecimal, formatYesNo } from 'components/utils/numberFormatting/numberFormat';
import { ReportPropertyRentalSurveyEventModel } from 'dto/reports/shared/reportPropertyRentalSurveyEvent';
import { ReportPropertyTaxAssessmentDetailEventModel } from 'dto/reports/shared/reportPropertyTaxAssessmentDetailEvent';
import { ReportPropertyTaxAssessmentConfigurationEventModel } from 'dto/reports/shared/reportPropertyTaxAssessmentConfigurationEvent';
import { ReportPropertyTaxAssessmentTotalEventModel } from 'dto/reports/shared/reportPropertyTaxAssessmentTotalEvent';
import PropertyTaxAssessmentAssessorValueDisplayTypeEnum from 'enums/propertyTaxAssessmentAssessorValueDisplayType';
import PropertyTaxAssessmentMarketValueTypeEnum from 'enums/propertyTaxAssessmentMarketValueType';
import { dateFormatter } from 'components/utils/columnFormatting/date';

type PropertyEventSharedProps = {
    sale: ReportSaleEventModel | null;
    rental: ReportRentalSurveyEventModel | null;
    incomeExpense: ReportIncomeAndExpenseEventModel | null;
    compTypeId: number;
};

const PropertyEventShared = ({ sale, rental, incomeExpense, compTypeId }: PropertyEventSharedProps) => {
    let model: any;
    if (compTypeId === PropertyEventTypeEnum.Sale) {
        model = sale;
    } else if (compTypeId === PropertyEventTypeEnum.RentalSurvey) {
        model = rental;
    }
    else if (compTypeId === PropertyEventTypeEnum.IncomeExpense) {
        model = incomeExpense;
    }

    const selfStoragePropertySubtype = 156;
    const majorMarketTypeDescription = 'Valuation Properties';
    const industrialPropertyType = 2;

    let summaryModel: ReportPropertyEventModel = model.reportPropertyEventModel;
    let isSummaryModelVisible = isModelVisible(summaryModel);
    let siteDetailModel: ReportPropertySiteDetailEventModel = model.reportPropertySiteDetailEventModel;
    let isSiteModelVisible = isModelVisible(siteDetailModel);
    let improvementModel: ReportPropertyImprovementDetailEventModel = model.reportPropertyImprovementDetailEventModel;
    let isImprovementModelVisible = isModelVisible(improvementModel);
    let industrialModel: ReportPropertyIndustrialBuildingDetailEventModel = model.reportPropertyIndustrialBuildingDetailEventModel;
    let isVisibleIndustrialModel: boolean = isModelVisible(industrialModel);
    let improvementLeaseModel: ReportPropertyImprovementLeaseDetailEventModel = model.reportPropertyImprovementLeaseDetailEventModel;
    let isVisibleImprovementLeaseModel = isModelVisible(improvementLeaseModel);
    let rentalSurveyModel: ReportPropertyRentalSurveyEventModel;
    let isVisibleRentalSurveyModel: boolean = false;
    if (compTypeId === PropertyEventTypeEnum.RentalSurvey) {
        rentalSurveyModel = model.reportPropertyRentalSurveyEventModel;
        isVisibleRentalSurveyModel = isModelVisible(rentalSurveyModel);
    }

    let unitMixModels: ReportPropertyUnitMixEventModel[] = model.reportPropertyUnitMixEventModels;
    let unitMixTotal: ReportPropertyUnitMixTotalEventModel = model.reportPropertyUnitMixTotalEventModel;
    let commercialModels: ReportPropertyCommercialSpaceModel[] = model.reportPropertyCommercialSpaceModels;
    let commercialSpaceTotal: ReportPropertyCommercialSpaceTotalEventModel = model.reportPropertyCommercialSpaceTotalEventModel;
    let landParcelGroupModel: ReportPropertyLandParcelGroupEventModel[] = model.reportPropertyLandParcelGroupEventModel;
    let landParcelDetailModel: ReportPropertyLandParcelDetailEventModel[] = model.reportPropertyLandParcelDetailEventModel;
    let landParcelTotalModel: ReportPropertyLandParcelTotalEventModel = model.reportPropertyLandParcelTotalEventModel;
    let checkLandParcelSmallAreaTotalEmpty: boolean = (landParcelDetailModel?.some(x => x.landAreaSmallUOM !== null));
    let checkLandParcelLargeAreaTotalEmpty: boolean = (landParcelDetailModel?.some(x => x.landAreaLargeUOM !== null));
    let isLandParcelUsableLineItemExist: boolean = (landParcelDetailModel?.some(x => x.propertyLandParcelClassificationTypeId === PropertyLandParcelClassificationTypeEnum.UsableLandArea));
    let isLandParcelUsableLineItemWithVal: boolean = (landParcelDetailModel?.some(x => x.propertyLandParcelClassificationTypeId === PropertyLandParcelClassificationTypeEnum.UsableLandArea && x.landAreaSmallUOM !== null));
    let isLandParcelExcessLineItemExist: boolean = (landParcelDetailModel?.some(x => x.propertyLandParcelClassificationTypeId === PropertyLandParcelClassificationTypeEnum.ExcessLandArea));
    let isLandParcelExcessLineItemWithVal: boolean = (landParcelDetailModel?.some(x => x.propertyLandParcelClassificationTypeId === PropertyLandParcelClassificationTypeEnum.ExcessLandArea && x.landAreaSmallUOM !== null));
    let isLandParcelSurplusLineItemExist: boolean = (landParcelDetailModel?.some(x => x.propertyLandParcelClassificationTypeId === PropertyLandParcelClassificationTypeEnum.SurplusLandArea));
    let isLandParcelSurplusLineItemWithVal: boolean = (landParcelDetailModel?.some(x => x.propertyLandParcelClassificationTypeId === PropertyLandParcelClassificationTypeEnum.SurplusLandArea && x.landAreaSmallUOM !== null));
    let taxAssessmentConfigurationModel: ReportPropertyTaxAssessmentConfigurationEventModel = model.reportPropertyTaxAssessmentConfigurationEventModel;
    let taxAssessmentModel: ReportPropertyTaxAssessmentDetailEventModel[] = model.reportPropertyTaxAssessmentDetailEventModel;
    let taxAssessmentTotal: ReportPropertyTaxAssessmentTotalEventModel = model.reportPropertyTaxAssessmentTotalEventModel;
    const inactiveMessage = "(Inactive)";
    let isAffordableHousing = improvementLeaseModel?.propertyOccupantTypeId === 1 ? true : false;
    let isSelfStoragePropertySubtype = summaryModel?.propertySubtypeId === selfStoragePropertySubtype;
    const currencySymbolCode = summaryModel.currencyTypeCode;

    function getEconomicGridDescription(unitMix: ReportPropertyUnitMixEventModel): string {
        let numOfBedrooms = formatValueWithDecimal(unitMix.numberOfBedrooms);
        let numOfBathrooms = formatValueWithDecimal(unitMix.numberOfBathrooms);
        let approximateUnitsSmallUOM = formatValueWithDecimal(unitMix.approximateUnitsSmallUOM);

        return `${numOfBedrooms}BR/${numOfBathrooms}BA ${unitMix.unitType ? unitMix.unitType : ''} - ${approximateUnitsSmallUOM} SF`;
    }


    function floodZoneDesignations(): string[] {
        let floodZoneDesignationList = siteDetailModel?.propertyFloodZoneDesignationTypes?.split(/[;,]/).map((a) => a.trim()).filter((a) => a.length > 0) ?? [];
        return filterAmenitiesUtilities(floodZoneDesignationList);
    }

    function getUnitAmenities(): string[] {
        let unitAminityTypes = improvementModel?.propertyUnitAminityTypes?.split(',').filter(a => a.trim().length > 0).map(a => a.trimStart()) || [];       
        return filterAmenitiesUtilities(unitAminityTypes);
    }

    function getBuildingCertifications(): string[] {
        let propertyBuildingCertificationTypes = improvementModel?.propertyBuildingCertificationTypes?.split(',').filter(a => a.trim().length > 0).map(a => a.trimStart()) || [];
        return propertyBuildingCertificationTypes;
    }

    function getPropertyAmenities(): string[] {
        let propertyAminityTypes = improvementModel?.propertyAminityTypes?.split(',').filter(a => a.trim().length > 0).map(a => a.trimStart()) || [];      
        return filterAmenitiesUtilities(propertyAminityTypes);
    }

    function getTenantPaidUtilities(): string[] {
        let propertyTenantPaidUtilityTypes = improvementLeaseModel?.propertyTenantPaidUtilityTypes?.split(',').filter(a => a.trim().length > 0).map(a => a.trimStart()) || [];     
        return filterAmenitiesUtilities(propertyTenantPaidUtilityTypes);
    }

    function propertyUtilitiesAvailableTypes(): string[] {
        let propertyUtilitiesAvailableTypes = siteDetailModel?.propertyUtilitiesAvailableTypes?.split(/[;,]/).map((a) => a.trim()).filter((a) => a.length > 0) ?? [];
        return propertyUtilitiesAvailableTypes;
    }

    function filterAmenitiesUtilities(aminityUtilities: string[] | null): string[] {
        if (aminityUtilities) {
            const others = aminityUtilities.filter(a => a === 'Other');
            aminityUtilities = aminityUtilities.filter(a => a !== 'Other').concat(others);
            return aminityUtilities;
        }
        return [];
    }

    function formatString(value: string) {
        if (value === null || value === undefined)
            return '';
        else
            return value;
    }

    const renderLocationProperty = () => {
        return (<>
            <Row>
                <Col md={6} sm={6} xs={6}>
                    <div>
                        <Row>
                            <div className="d-inline ps-2 p-1 mt-1 heading-bg-primary">
                                <h5 className="title mb-1 fw-bold">Location & Property Info</h5>
                            </div>
                        </Row>
                        <Row>
                            {summaryModel.isVisibleName &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Property Name</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.name}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisiblePropertyTypeDescription &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Property Type</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.propertyTypeDescription}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisiblePropertySubtypeDescription &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Sub Type</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.propertySubtypeDescription}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleMajorMarketAreaTypeDescription &&
                                summaryModel.majorMarketAreaTypeDescription !== majorMarketTypeDescription &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Major Market</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.majorMarketAreaTypeDescription}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleSubmarketTypeDescription &&
                                summaryModel.majorMarketAreaTypeDescription !== majorMarketTypeDescription &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Sub Market</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.submarketTypeDescription}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleMarketComments &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Market Comments</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.marketComments}</span>
                                    </Col>
                                </Row>
                            }
                            {renderAddress()}
                            {summaryModel.isVisibleCounty &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">County</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.county}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleCountryId &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Country</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.country}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleLocation &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Location</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.location}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleLatitude &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Latitude</label>
                                    </Col>
                                    <Col xs={6}>
                                        {summaryModel.isConfidential ?
                                            <span className="">Confidential</span>
                                            : <span className="">{formatNumberWithDecimals(summaryModel.latitude, 8)}</span>}
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleLongitude &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Longitude</label>
                                    </Col>
                                    <Col xs={6}>
                                        {summaryModel.isConfidential ?
                                            <span className="">Confidential</span>
                                            : <span className="">{formatNumberWithDecimals(summaryModel.longitude, 8)}</span>
                                        }

                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleMetropolitanStatisticalArea &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">MSA</label>
                                    </Col>
                                    <Col xs={6}>
                                        {summaryModel.isConfidential ?
                                            <span >Confidential</span>
                                            : <span className="fw-normal">{summaryModel.metropolitanStatisticalArea}</span>
                                        }

                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleAssessorsParcelNumber &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Legal/Tax/Parcel ID</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.assessorsParcelNumber}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleMarketOrientationDescription &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Market Orientation</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.marketOrientationDescription}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleVerificationTypeDescription &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Verification Type</label>
                                    </Col>
                                    <Col xs={6}>
                                        {summaryModel.isConfidential ?
                                            <span className="">Confidential</span>
                                            : <span className="fw-normal">{summaryModel.verificationTypeDescription}</span>
                                        }
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isVisibleVerificationSource &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Verification Source</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">{summaryModel.verificationSource}</span>
                                    </Col>
                                </Row>
                            }
                            {summaryModel.isConfidential &&
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Confidential</label>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fw-normal">Confidential</span>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col xs={5}>
                                    <label className="light-text">Event ID</label>
                                </Col>
                                <Col xs={6}>
                                    <span className="fw-normal">{summaryModel.id}</span>
                                    {summaryModel.isVisibleIsInactive ? inactiveMessage : ''}
                                </Col>
                            </Row>
                        </Row>
                    </div>
                </Col>
                <Col md={6} sm={6} xs={6}>
                    {(model.imageUrl !== '' || model.imageUrl !== null) && model.imageUrl && !summaryModel.isConfidential ?


                        <div className="card bg-white border-0 mt-1">
                            <img className="card-img-top rounded-0" src={model.imageUrl} alt="location img" />

                        </div>
                        : <></>
                    }
                </Col>
            </Row >
        </>);
    };
    const renderAddress = () => {
        const completeAddress = (): string => {
            let addressParts = [formatString(summaryModel.address1), formatString(summaryModel.address2), formatString(summaryModel.city), formatString(summaryModel.state) + ' ' + formatString(summaryModel.zip)];
            let filteredAddressParts = addressParts.filter(part => part.trim() !== '');
            return filteredAddressParts.join(', ');
        };
        const completeAddress1 = (): string => {
            let addressParts = [formatString(summaryModel.address1), formatString(summaryModel.city), formatString(summaryModel.state) + ' ' + formatString(summaryModel.zip)];
            let filteredAddressParts = addressParts.filter(part => part.trim() !== '');
            return filteredAddressParts.join(', ');
        };
        const cityState = (): string => {
            let addressParts = [formatString(summaryModel.city), formatString(summaryModel.state)];
            let filteredAddressParts = addressParts.filter(part => part.trim() !== '');
            return filteredAddressParts.join(', ');
        };
        return (
            <>
                {
                    summaryModel.isVisibleAddress1 && !summaryModel.isConfidential ?
                        <Row>
                            <Col xs={5}>
                                <label className="light-text">Address</label>
                            </Col>
                            <Col xs={6}>
                                {summaryModel.isVisibleAddress2 ?
                                    <span className="fw-normal">{completeAddress()}</span>
                                    :
                                    <span className="fw-normal"> {completeAddress1()}</span>
                                }
                            </Col>
                        </Row>
                        : summaryModel.isConfidential ? summaryModel.isVisibleAddress1 ?
                            <Row>
                                <Col xs={5}>
                                    <label className="light-text">Address</label>
                                </Col>
                                <Col xs={6}>
                                    <span className="fw-normal">{summaryModel.address1}</span>
                                </Col>
                            </Row> : summaryModel.isVisibleCity || summaryModel.isVisibleState ?
                                <Row>
                                    <Col xs={5}>
                                        <label className="light-text">Address</label>
                                    </Col>
                                    <Col xs={6}>
                                        {summaryModel.isVisibleCity && summaryModel.isVisibleState ?
                                            <span className="fw-normal">{cityState()}</span>
                                            : summaryModel.isVisibleCity ? <span className="light-text">{summaryModel.city}</span>
                                                : <span className="fw-normal">{summaryModel.state}</span>
                                        }
                                    </Col>
                                </Row>
                                : '' : ''
                }
            </>
        )
    };

    const renderSiteDetails = () => {
        return (<>
            <Row>
                <div className="d-inline p-1 mt-3 heading-bg-primary">
                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Site Details</h5>
                </div>
            </Row>
            <Row>
                <Col md={4} sm={4} xs={4}>
                    {(siteDetailModel.isVisibleUsableGrossRatioSmallUOM || siteDetailModel.isVisiblePotentialUnits || siteDetailModel.isVisiblePotentialFloorAreaRatio
                        || siteDetailModel.isVisiblePotentialFloorAreaRatio) &&
                        <div>
                            <label className='fw-bold'>SUMMARY</label>
                        </div>
                    }
                    {siteDetailModel.isVisibleUsableGrossRatioSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Usable/Gross Ratio</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(siteDetailModel.usableGrossRatioSmallUOM, 2)}</label>

                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePotentialUnits &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Potential Units</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(siteDetailModel.potentialUnits)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePotentialFloorAreaRatio &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Potential Bldg to Land Ratio FAR</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(siteDetailModel.potentialFloorAreaRatio, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleSourceOfLandInfo &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Source Of LandInfo</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.sourceOfLandInfo}</label>
                            </Col>
                        </Row>
                    }
                    {(siteDetailModel.isVisibleFrontageStreetName || siteDetailModel.isVisibleFrontageSmallUOM || siteDetailModel.isVisibleSecondaryFrontageStreetName
                        || siteDetailModel.isVisibleSecondaryFrontageSmallUOM || siteDetailModel.isVisibleAdditionalFrontageStreetName || siteDetailModel.isVisibleAdditionalFrontageSmallUOM
                        || siteDetailModel.isVisiblePropertyTrafficControlAtEntryTypeDescription || siteDetailModel.isVisiblePropertyTrafficFlowTypeDescription || siteDetailModel.isVisiblePropertyAccessibilityRatingTypeDescription
                        || siteDetailModel.isVisiblePropertyVisibilityRatingTypeDescription || siteDetailModel.isVisibleSiteShape || siteDetailModel.isVisibleRailAccess || siteDetailModel.isVisibleSiteTopography || siteDetailModel.isVisibleVegetation
                        || siteDetailModel.isVisiblePropertyOtherCharacteristicsTypes || siteDetailModel.cornerLot != null) &&
                        <div>
                            <label className='fw-bold'>SITE CHARACTERISTICS</label>
                        </div>
                    }
                    {(siteDetailModel.isVisibleFrontageStreetName || siteDetailModel.isVisibleFrontageSmallUOM) &&
                        <div>
                            <label>PRIMARY STREET FRONTAGE</label>
                        </div>
                    }
                    {siteDetailModel.isVisibleFrontageStreetName &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text ps-8'> Frontage Street Name</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.frontageStreetName}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleFrontageSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text ps-8'>Frontage Feet</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(siteDetailModel.frontageSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {(siteDetailModel.isVisibleSecondaryFrontageStreetName || siteDetailModel.isVisibleSecondaryFrontageSmallUOM) &&
                        <div>
                            <label>SECONDARY STREET FRONTAGE</label>
                        </div>
                    }
                    {siteDetailModel.isVisibleSecondaryFrontageStreetName &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text ps-8'>Frontage Street Name</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.secondaryFrontageStreetName}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleSecondaryFrontageSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text ps-8'>Frontage Feet</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(siteDetailModel.secondaryFrontageSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {(siteDetailModel.isVisibleAdditionalFrontageStreetName || siteDetailModel.isVisibleAdditionalFrontageSmallUOM) &&
                        <div>
                            <label>ADDITIONAL STREET FRONTAGE</label>
                        </div>
                    }
                    {siteDetailModel.isVisibleAdditionalFrontageStreetName &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text ps-8'>Frontage Street Name</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.additionalFrontageStreetName}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleAdditionalFrontageSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text ps-8'>Frontage Feet</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(siteDetailModel.additionalFrontageSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyTrafficControlAtEntryTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Traffic Control at Entry</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.propertyTrafficControlAtEntryTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyTrafficFlowTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Traffic Flow</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.propertyTrafficFlowTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyAccessibilityRatingTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Accessibility Rating</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.propertyAccessibilityRatingTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyVisibilityRatingTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Visibility Rating</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.propertyVisibilityRatingTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleSiteShape &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Site Shape</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.siteShape}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.cornerLot !== null &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Corner</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatYesNo(siteDetailModel.cornerLot)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleRailAccess &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Rail Access</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.railAccess}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleSiteTopography &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Site Topography</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.siteTopography}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleVegetation &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Site Vegetation</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.vegetation}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyOtherCharacteristicsTypes &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Other Site Characteristics</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.propertyOtherCharacteristicsTypes}</label>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col xs={4}>
                    {(siteDetailModel.isVisibleFloodMap || siteDetailModel.isVisibleFloodMapDate || siteDetailModel.isVisiblePropertyFloodZoneDesignationTypes
                        || siteDetailModel.isVisiblePropertyFloodInsuranceRequiredTypeDescription) &&
                        <div>
                            <label className='fw-bold'>FLOOD ZONE ANALYSIS</label>
                        </div>
                    }
                    {siteDetailModel.isVisibleFloodMap &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>FIRM Panel Number</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.floodMap}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleFloodMapDate &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Map Date</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{dateFormatter(siteDetailModel.floodMapDate)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyFloodZoneDesignationTypes &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Flood Zone Designation</label>
                            </Col>
                            <Col xs={5}>
                                {floodZoneDesignations().map((value, index) => (
                                    <span className='fw-normal ps-2 text-break'>• {value} <br /></span>))}
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyFloodInsuranceRequiredTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Flood Insurance Required</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.propertyFloodInsuranceRequiredTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {(siteDetailModel.isVisibleZoning || siteDetailModel.isVisibleZoningDescription || siteDetailModel.isVisiblePermittedUses
                        || siteDetailModel.legallyConforming != null || siteDetailModel.zoningChange != null) &&
                        <div>
                            <label className='fw-bold'>ZONING</label>
                        </div>
                    }
                    {siteDetailModel.isVisibleZoning &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Zoning Designation</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.zoning}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisibleZoningDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Zoning Description</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.zoningDescription}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePermittedUses &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Permitted Uses</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{siteDetailModel.permittedUses}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.legallyConforming !== null &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Legally Conforming?</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatYesNo(siteDetailModel.legallyConforming)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.zoningChange !== null &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Zoning Change Likely?</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatYesNo(siteDetailModel.zoningChange)}</label>
                            </Col>
                        </Row>
                    }
                    {siteDetailModel.isVisiblePropertyUtilitiesAvailableTypes &&
                        <div>
                            <label className='fw-bold'>UTILITIES</label>
                        </div>
                    }
                    {siteDetailModel.isVisiblePropertyUtilitiesAvailableTypes &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Utilities</label>
                            </Col>
                            <Col xs={5}>
                                {propertyUtilitiesAvailableTypes().map((value, index) => (
                                    <div className='fw-normal ps-2 text-break'> • {value} <br /></div>))}
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>);
    };

    const renderImprovementDetails = () => {
        return (<><Row>
            <div className="d-inline p-1 mt-3 heading-bg-primary">
                <h5 className="title mb-1 ps-2 p-1 fw-bold">Improvement Details</h5>
            </div>
        </Row>
            <Row>
                <Col md={4} sm={4} xs={4}>
                    {(improvementModel.isVisibleNumberOfBuildings || improvementModel.isVisiblePropertyDemisedUnitOfComparisonTypeDescription || improvementModel.isVisibleNumberOfDemisedUnits || improvementModel.isVisibleGrossBuildingAreaSmallUOM
                        || improvementModel.isVisibleRentableAreaSmallUOM || improvementModel.isVisibleAverageUnitSizeSmallUOM
                        || improvementModel.isVisiblePropertyConstructionQualityTypeDescription || improvementModel.isVisiblePropertyConditionTypeDescription || improvementModel.isVisibleBuildingInvestmentClassTypeDescription) &&
                        <div>
                            <label className='fw-bold'>BASIC CHARACTERISTICS</label>
                        </div>
                    }
                    {improvementModel.isVisibleNumberOfBuildings &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Number Of Buildings</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.numberOfBuildings)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyDemisedUnitOfComparisonTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Demised Unit Of Comparison</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.propertyDemisedUnitOfComparisonTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleNumberOfDemisedUnits &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Number of Demised Units</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.numberOfDemisedUnits)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleGrossBuildingAreaSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Gross Building Area (SF)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.grossBuildingAreaSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleRentableAreaSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Rentable Area (SF)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.rentableAreaSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleAverageUnitSizeSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Average Unit Size (SF)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.averageUnitSizeSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyConstructionQualityTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Quality</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.propertyConstructionQualityTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyConditionTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Condition</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.propertyConditionTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleBuildingInvestmentClassTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Investment Class</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.buildingInvestmentClassTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {(improvementModel.isVisibleYearBuilt || improvementModel.isVisibleYearRenovated || improvementModel.isVisibleActualAge
                        || improvementModel.isVisibleEconomicLife || improvementModel.isVisibleEffectiveAgeYears
                        || improvementModel.isVisibleRemainingEconomicLife || improvementModel.isVisiblePercentDepreciation) &&
                        <div>
                            <label className='fw-bold'>AGE/LIFE DEPRECIATION ANALYSIS</label>
                        </div>
                    }
                    {improvementModel.isVisibleYearBuilt &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Year Built</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.yearBuilt}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleYearRenovated &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Year Renovated</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.yearRenovated}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleActualAge &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Actual Age (Years)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.actualAge}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleEconomicLife &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Economic Life (Years)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.economicLife}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleEffectiveAgeYears &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Effective Age (Years)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.effectiveAgeYears)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleRemainingEconomicLife &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Remaining Economic Life (Years)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.remainingEconomicLife}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePercentDepreciation &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text text-break'>Percent Depreciation</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'> {formatNumberWithDecimalsWithPercentage(improvementModel.percentDepreciation, 4)}</label>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col md={4} sm={4} xs={4}>
                    {(improvementModel.isVisibleNumberOfStoriesFloors || improvementModel.isVisibleCeilingHeightMaxSmallUOM || improvementModel.isVisibleBuildingShape
                        || improvementModel.isVisibleEstGroundFloorAreaSmallUOM || improvementModel.isVisibleFloorAreaRatio || improvementModel.isVisibleUsableUnitDensityLargeUOM
                        || improvementModel.isVisibleLandToBuildingRatio || improvementModel.isVisibleLoadFactor || improvementModel.isVisibleParking || improvementModel.isVisibleNumberCoveredParkingSpaces
                        || improvementModel.isVisibleNumberOpenParkingSpaces || improvementModel.isVisibleNumberGarageParkingSpaces || improvementModel.isVisibleNumberParkingSpaces || improvementModel.isVisibleParkingRatioPerUnit
                        || improvementModel.isVisibleParkingSpacesPerRentableAreaSmallUOM) &&
                        <div>
                            <label className='fw-bold'>FLOOR AREA ANALYSIS</label>
                        </div>
                    }
                    {improvementModel.isVisibleNumberOfStoriesFloors &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text text-break'>Number of Stories/Floors</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(improvementModel.numberOfStoriesFloors, 2)}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleCeilingHeightMaxSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Max Ceiling Height</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.ceilingHeightMaxSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleBuildingShape &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Building Shape</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.buildingShape}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleEstGroundFloorAreaSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Est. Ground Floor Area (GBA)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.estGroundFloorAreaSmallUOM)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleFloorAreaRatio &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Floor Area Ratio (FAR)</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(improvementModel.floorAreaRatio, 3)}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleUsableUnitDensityLargeUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Density</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(improvementModel.usableUnitDensityLargeUOM, 1)}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleLandToBuildingRatio &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Land to Building Ratio</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(improvementModel.landToBuildingRatio, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleLoadFactor &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Load Factor</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimalsWithPercentage(improvementModel.loadFactor, 4)}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleParking &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Parking Description</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.parking}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleNumberCoveredParkingSpaces &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Covered Parking Spaces</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.numberCoveredParkingSpaces)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleNumberOpenParkingSpaces &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Open Parking Spaces</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.numberOpenParkingSpaces)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleNumberGarageParkingSpaces &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Garage Parking Spaces</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'> {formatNumberWithNoDecimals(improvementModel.numberGarageParkingSpaces)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleNumberParkingSpaces &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Total Parking Spaces</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'> {formatNumberWithNoDecimals(improvementModel.numberParkingSpaces)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleParkingRatioPerUnit &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Parking Ratio Per Unit</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(improvementModel.parkingRatioPerUnit, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleParkingSpacesPerRentableAreaSmallUOM &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Spaces/1,000 SF NRA Ratio</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithDecimals(improvementModel.parkingSpacesPerRentableAreaSmallUOM, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {(improvementModel.isVisibleImprovementsConstructionClassTypeDescription || improvementModel.isVisibleConstructionDescription || improvementModel.isVisibleFoundation
                        || improvementModel.isVisibleBasement || improvementModel.isVisiblePropertyConstructionTypeDescription || improvementModel.isVisibleExteriorWalls
                        || improvementModel.isVisibleWindows || improvementModel.isVisibleRoofType || improvementModel.isVisibleParking || improvementModel.isVisibleNumberCoveredParkingSpaces
                        || improvementModel.isVisibleNumberOpenParkingSpaces || improvementModel.isVisibleNumberGarageParkingSpaces || improvementModel.isVisibleParkingRatioPerUnit
                        || improvementModel.isVisibleParkingSpacesPerRentableAreaSmallUOM) &&
                        <div>
                            <label className='fw-bold'>CONSTRUCTION DETAILS</label>
                        </div>
                    }
                    {improvementModel.isVisibleImprovementsConstructionClassTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Construction Class</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.improvementsConstructionClassTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleConstructionDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Construction Description</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.constructionDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleFoundation &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Foundation</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.foundation}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleBasement &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Basement</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.basement}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyConstructionTypeDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Construction Type</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.propertyConstructionTypeDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleExteriorWalls &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Exterior Walls</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.exteriorWalls}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleWindows &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Windows</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.windows}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleRoofType &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Roof Description</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.roofType}</label>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col md={4} sm={4} xs={4}>
                    {(improvementModel.isVisibleInteriorFinishFloors || improvementModel.isVisibleInteriorFinishWalls || improvementModel.isVisibleInteriorFinishCeilings
                        || improvementModel.isVisibleInteriorFinishLighting || improvementModel.isVisibleInteriorFinishOther) &&
                        <div>
                            <label className='fw-bold'>INTERIOR FINISH</label>
                        </div>
                    }
                    {improvementModel.isVisibleInteriorFinishFloors &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Floors</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishFloors}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleInteriorFinishWalls &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Walls</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishWalls}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleInteriorFinishCeilings &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Ceilings</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishCeilings}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleInteriorFinishLighting &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Lighting</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishLighting}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleInteriorFinishOther &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Other</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishOther}</label>
                            </Col>
                        </Row>
                    }

                    {(improvementModel.isVisibleHVACComments || improvementModel.isVisibleInteriorFinishElectrical || improvementModel.isVisibleInteriorFinishPlumbing
                        || improvementModel.isVisibleUtilityMeters || improvementModel.isVisibleElevatorsCount || improvementModel.isVisibleRestrooms || improvementModel.isVisibleFireSprinklerType) &&
                        <div>
                            <label className='fw-bold'>ENGINEERING AND MECHANICAL</label>
                        </div>
                    }
                    {improvementModel.isVisibleHVACComments &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>HVAC</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.hvacComments}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleInteriorFinishElectrical &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Electrical</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishElectrical}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleInteriorFinishPlumbing &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Plumbing</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.interiorFinishPlumbing}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleUtilityMeters &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Utility Meters</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.utilityMeters}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleElevatorsCount &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Elevators Count</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{formatNumberWithNoDecimals(improvementModel.elevatorsCount)}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleRestrooms &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Restrooms</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.restrooms}</label>

                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleFireSprinklerType &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Fire Sprinkler Type</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.fireSprinklerType}</label>
                            </Col>
                        </Row>
                    }
                    {(improvementModel.isVisiblePropertyAminityTypes || improvementModel.isVisibleUnitAmenitiesScoreDescription || improvementModel.isVisiblePropertyAmenitiesScoreDescription
                        || improvementModel.isVisiblePropertyUnitAminityTypes || improvementModel.isVisibleUnitAmenitiesScoreDescription || improvementModel.isVisibleUnitAmenitiesScoreDescription
                        || improvementModel.isVisibleSpecialFeatures || improvementModel.isVisiblePropertyBuildingCertificationTypes || improvementModel.isVisibleEnergyStarRating || improvementModel.isVisibleEnergyUseIntensity) &&
                        <div>
                            <label className='fw-bold'>IMPROVEMENT FEATURES AND AMENITIES</label>
                        </div>
                    }
                    {improvementModel.isVisiblePropertyAminityTypes &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Property Amenities</label>
                            </Col>
                            <Col xs={5}>
                                {getPropertyAmenities().map((value, index) => (
                                    <div className='fw-normal ps-2 text-break'>• {value} <br /></div>))}
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleAmenities &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Additional Property Amenities</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.amenities}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyAmenitiesScoreDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Property Amenities Score</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.propertyAmenitiesScoreDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyUnitAminityTypes &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text text-break'>Unit Amenities</label>
                            </Col>
                            <Col xs={5}>
                                {getUnitAmenities().map((value, index) => (
                                    <div className='fw-normal ps-2 text-break'>• {value} <br /></div>))}
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleUnitAmenities &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Additional Unit Amenities</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.unitAmenities}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleUnitAmenitiesScoreDescription &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Unit Amenities Score</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.unitAmenitiesScoreDescription}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisiblePropertyBuildingCertificationTypes &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Building Certification</label>
                            </Col>
                            <Col xs={5}>
                                {getBuildingCertifications().map((value, index) => (
                                    <div className='fw-normal ps-2 text-break'>• {value} <br /></div>))}
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleEnergyUseIntensity &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Energy Use Intensity</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.energyUseIntensity}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleEnergyStarRating &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Energy Star Rating</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.energyStarRating}</label>
                            </Col>
                        </Row>
                    }
                    {improvementModel.isVisibleSpecialFeatures &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Special Features</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2 text-break'>{improvementModel.specialFeatures}</label>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>);
    };

    const renderTaxAssessments = () => {
        return (
            <>
                <Row>
                    <div className="d-inline ps-2 p-1 mt-1 heading-bg-primary">
                        <h5 className="title mb-1 fw-bold">Taxes & Assessments</h5>
                    </div>
                </Row>

                <Row>
                    <div className="mt-1 p-0">
                        <table className="data-sheet-table table table-condensed table-hover mb-0">
                            <thead className='table-header'>
                                <tr>
                                    <th className="tax-year-width" colSpan={1}>
                                        {taxAssessmentConfigurationModel.taxYear.toUpperCase()}
                                    </th>
                                    {taxAssessmentConfigurationModel.propertyTaxAssessmentAssessorValueDisplayTypeId ===
                                        PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ? (
                                        <>
                                            {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !==
                                                PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay && (
                                                    <th className="market-value-width text-center" colSpan={1}>
                                                        {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeDescription.toUpperCase()}
                                                    </th>
                                                )}
                                                <th className="assessed-value-width text-center" colSpan={1}>
                                                {taxAssessmentConfigurationModel.propertyTaxAssessmentValueTypeDescription.toUpperCase()}
                                            </th>
                                        </>
                                    ) : (
                                        <>
                                            {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !==
                                                PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay && (
                                                    <th colSpan={4}>
                                                        {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeDescription.toUpperCase()}
                                                    </th>
                                                )}
                                            <th colSpan={3}>
                                                {" "}
                                                {taxAssessmentConfigurationModel.propertyTaxAssessmentValueTypeDescription.toUpperCase()}{" "}
                                            </th>
                                        </>
                                    )}
                                    {taxAssessmentConfigurationModel.propertyTaxAssessmentRateDisplayTypeId ===
                                        PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ? (
                                        <>
                                                <th colSpan={1} className="text-center tax-rate-width">
                                                {taxAssessmentConfigurationModel.propertyTaxAssessmentRateTypeDescription.toUpperCase()}
                                            </th>
                                        </>
                                    ) : (
                                        <>
                                                <th colSpan={2} className="text-center tax-asses-width">
                                                {taxAssessmentConfigurationModel.propertyTaxAssessmentRateTypeDescription.toUpperCase()}
                                            </th>
                                        </>
                                    )}
                                    <th colSpan={3} className="text-center">
                                        TAXES AND ASSESSMENTS
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <table className="data-sheet-table table table-condensed table-hover">
                            <thead className='table-header'>
                                <tr>
                                    <th>
                                        <label className="fw-bold">APN</label>
                                    </th>
                                    {taxAssessmentConfigurationModel.propertyTaxAssessmentAssessorValueDisplayTypeId ===
                                        PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ? (
                                        <>
                                            {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !==
                                                PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay && (
                                                    <>
                                                        <th className='text-end'>
                                                            <label className="fw-bold">LAND & IMPVT.</label>
                                                        </th>
                                                        <th className='text-end'>
                                                            <label className="fw-bold">
                                                                ASSESSMENT RATIO
                                                            </label>
                                                        </th>
                                                    </>
                                                )}
                                            <th className='text-end'>
                                                <label className="fw-bold">LAND & IMPVT.</label>
                                            </th>
                                        </>
                                    ) : (
                                        <>
                                            {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !==
                                                PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay && (
                                                    <>
                                                        <th className='text-end'>
                                                            <label className="fw-bold">LAND</label>
                                                        </th>
                                                        <th className='text-end'>
                                                            <label className="fw-bold">IMPVT.</label>
                                                        </th>
                                                        <th className='text-end'>
                                                            <label className="fw-bold">TOTAL</label>
                                                        </th>
                                                        <th className='text-end'>
                                                            <label className="fw-bold">
                                                                ASSESSMENT RATIO
                                                            </label>
                                                        </th>
                                                    </>
                                                )}
                                            <th className='text-end'>
                                                <label className="fw-bold">LAND</label>
                                            </th>
                                            <th className='text-end'>
                                                <label className="fw-bold">IMPVT.</label>
                                            </th>
                                            <th className='text-end'>
                                                <label className="fw-bold">TOTAL</label>
                                            </th>
                                        </>
                                    )}
                                    {taxAssessmentConfigurationModel.propertyTaxAssessmentRateDisplayTypeId ===
                                        PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ? (
                                        <>
                                            <th className='text-end'>
                                                <label className="fw-bold">LAND & IMPVT.</label>
                                            </th>
                                        </>
                                    ) : (
                                        <>
                                            <th className='text-end'>
                                                <label className="fw-bold">LAND</label>
                                            </th>
                                            <th>
                                                <label className="fw-bold">IMPVT.</label>
                                            </th>
                                        </>
                                    )}
                                    <th className='text-end'>
                                        <label className="fw-bold">ADVALOREM TAXES</label>
                                    </th>
                                    <th className='text-end'>
                                        <label className="fw-bold">{taxAssessmentConfigurationModel.propertyTaxAssessmentTypeDescription.toUpperCase()}</label>
                                    </th>
                                    <th className='text-end'>
                                        <label className="fw-bold">TOTAL</label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {taxAssessmentModel.map((taxAssessment, index) => (
                                    <tr>
                                        {taxAssessmentConfigurationModel.propertyTaxAssessmentAssessorValueDisplayTypeId === PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ?
                                            (<>
                                                <td className='text-start'>{taxAssessment.assessorsParcelNumber}</td>
                                                {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !== PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay &&
                                                    <>
                                                        <td className='text-end'> {formatNumberWithDecimalsWithCurrency(taxAssessment.assessorMarketFeeSimpleTotalValue, currencySymbolCode, 2)}</td>
                                                        {taxAssessment.assessmentRatio ? (<td className='text-end'>{formatNumberWithDecimalsWithPercentage(taxAssessment.assessmentRatio, 2)}</td>) : (<td className='text-end'>0.00%</td>)}
                                                    </>
                                                }
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessedAlternateTaxableTotalValue, currencySymbolCode, 2)}</td>
                                            </>)
                                            : (<>
                                                <td className='text-end'>{taxAssessment.assessorsParcelNumber}</td>
                                                {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !== PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay &&
                                                    <>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessorMarketFeeSimpleLandValue, currencySymbolCode, 2)}</td>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessorMarketFeeSimpleImprovementsValue, currencySymbolCode, 2)}</td>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessorMarketFeeSimpleTotalValue, currencySymbolCode, 2)}</td>
                                                        {taxAssessment.assessmentRatio ? (<td className='text-end'>{formatNumberWithDecimalsWithPercentage(taxAssessment.assessmentRatio, 2)}</td>) : (<td className='text-end'>0.00%</td>)}
                                                    </>
                                                }
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessedAlternateTaxableLandValue, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessedAlternateTaxableImprovementsValue, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.assessedAlternateTaxableTotalValue, currencySymbolCode, 2)}</td>
                                            </>)}
                                        {taxAssessmentConfigurationModel.propertyTaxAssessmentRateDisplayTypeId === PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ?
                                            (<>
                                                <td className='text-end'>{formatNumberWithDecimals(taxAssessment.taxMillageLandAndImprovementsRate, 5)}</td>
                                            </>)
                                            : (<>
                                                <td className='text-end'>{formatNumberWithDecimals(taxAssessment.taxMillageLandRate, 5)}</td>
                                                <td className='text-end'>{formatNumberWithDecimals(taxAssessment.taxMillageImprovementsRate, 5)}</td>
                                            </>)}
                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.adValoremTaxValue, currencySymbolCode, 2)}</td>
                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.directPIDMUDAssessmentValue, currencySymbolCode, 2)}</td>
                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessment.totalTaxValue, currencySymbolCode, 2)}</td>
                                    </tr>
                                ))}
                                <tr className="fw-bold">
                                    <td><label>Total</label></td>
                                    {taxAssessmentConfigurationModel.propertyTaxAssessmentAssessorValueDisplayTypeId === PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ?
                                        (<>
                                            {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !== PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay ?
                                                (<>
                                                    {taxAssessmentTotal.totalAssessorMarketFeeSimpleTotalValue !== 0 ?
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessorMarketFeeSimpleTotalValue, currencySymbolCode, 2)}</td> : <td></td>
                                                    }
                                                    {taxAssessmentTotal.totalAssessmentRatio !== 0 ? <td className='text-end'>{formatNumberWithDecimals(taxAssessmentTotal.totalAssessmentRatio, 2)}%</td> : <td></td>}
                                                </>
                                                ) : <></>
                                            }
                                            {taxAssessmentTotal.totalAssessedAlternateTaxableTotalValue !== 0 ? <td className='text-end'> {formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessedAlternateTaxableTotalValue, currencySymbolCode, 2)}</td> : <td></td>}
                                        </>)
                                        : (<>
                                            {taxAssessmentConfigurationModel.propertyTaxAssessmentMarketValueTypeId !== PropertyTaxAssessmentMarketValueTypeEnum.DoNotDisplay ?
                                                <>
                                                    {taxAssessmentTotal.totalAssessorMarketFeeSimpleLandValue !== 0 ? <td className='text-end'> {formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessorMarketFeeSimpleLandValue, currencySymbolCode, 2)}</td> : <td></td>}
                                                    {taxAssessmentTotal.totalAssessorMarketFeeSimpleImprovementsValue !== 0 ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessorMarketFeeSimpleImprovementsValue, currencySymbolCode, 2)}</td> : <td></td>}
                                                    {taxAssessmentTotal.totalAssessorMarketFeeSimpleTotalValue !== 0 ? <td className='text-end'> {formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessorMarketFeeSimpleTotalValue, currencySymbolCode, 2)}</td> : <td></td>}
                                                    {taxAssessmentTotal.totalAssessmentRatio !== 0 ? <td className='text-end'>{formatNumberWithDecimals(taxAssessmentTotal.totalAssessmentRatio, 2)}%</td> : <td></td>}
                                                </> : <></>}
                                            {taxAssessmentTotal.totalAssessedAlternateTaxableLandValue !== 0 ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessedAlternateTaxableLandValue, currencySymbolCode, 2)}</td> : <td></td>}
                                            {taxAssessmentTotal.totalAssessedAlternateTaxableImprovementsValue ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessedAlternateTaxableImprovementsValue, currencySymbolCode, 2)}</td> : <td></td>}
                                            {taxAssessmentTotal.totalAssessedAlternateTaxableTotalValue !== 0 ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAssessedAlternateTaxableTotalValue, currencySymbolCode, 2)}</td> : <td></td>}
                                        </>)}
                                    {taxAssessmentConfigurationModel.propertyTaxAssessmentRateDisplayTypeId === PropertyTaxAssessmentAssessorValueDisplayTypeEnum.LandAndImprovementCombined ?
                                        (<>{taxAssessmentTotal.totalTaxMillageLandAndImprovementsRate !== 0 ? <td className='text-end'>{formatNumberWithDecimals(taxAssessmentTotal.totalTaxMillageLandAndImprovementsRate, 5)}</td> : <td></td>}</>)
                                        : (<>
                                            {taxAssessmentTotal.totalTaxMillageLandRate !== 0 ? <td className='text-end'>{formatNumberWithDecimals(taxAssessmentTotal.totalTaxMillageLandRate, 5)}</td> : <td></td>}
                                            {taxAssessmentTotal.totalTaxMillageImprovementsRate !== 0 ? <td className='text-end'>{formatNumberWithDecimals(taxAssessmentTotal.totalTaxMillageImprovementsRate, 5)}</td> : <td></td>}
                                        </>)}
                                    {taxAssessmentTotal.totalAdValoremTaxValue !== 0 ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalAdValoremTaxValue, currencySymbolCode, 2)}</td> : <td></td>}
                                    {taxAssessmentTotal.totalDirectPIDMUDAssessmentValue !== 0 ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalDirectPIDMUDAssessmentValue, currencySymbolCode, 2)}</td> : <td></td>}
                                    {taxAssessmentTotal.totalTaxValue !== 0 ? <td className='text-end'>{formatNumberWithDecimalsWithCurrency(taxAssessmentTotal.totalTaxValue, currencySymbolCode, 2)}</td> : <td></td>}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Row>
            </>
        );
    };

    const renderLeaseAvailabilityInfo = () => {
        return (<>
            <Row>
                <div className="d-inline ps-1 p-1 mt-1 heading-bg-primary">
                    <h5 className="title mb-1 ps-2 fw-bold">Lease Availability Information</h5>
                </div>
            </Row>
            <Row>
                <Col md={4} sm={4} xs={4}>
                    {rentalSurveyModel.isVisibleSurveyDate &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Survey Date</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2'>{dateFormatter(rentalSurveyModel.surveyDate)}</label>
                            </Col>
                        </Row>
                    }
                    {rentalSurveyModel.isVisibleVacancyAtSurveyPercentage &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Occupancy At Survey</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2'>{formatNumberWithDecimalsWithPercentage(rentalSurveyModel.vacancyAtSurveyPercentage, 2)}</label>
                            </Col>
                        </Row>
                    }
                    {rentalSurveyModel.isVisibleLeasingAgentName &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Leasing Agent</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2'>{rentalSurveyModel.leasingAgentName}</label>
                            </Col>
                        </Row>
                    }
                    {rentalSurveyModel.isVisibleCompany &&
                        <Row>
                            <Col xs={7}>
                                <label className='light-text'>Company</label>
                            </Col>
                            <Col xs={5}>
                                <label className='fw-normal ps-2'>{rentalSurveyModel.company}</label>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>);
    };

    const renderLandParcel = () => {
        return (<>
            <Row>
                <div className="p-1 mt-3 heading-bg-primary">
                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Land Parcels</h5>
                </div>
            </Row>
            <Row>
                {landParcelGroupModel.map(group => {
                    const landParcelDetail = landParcelDetailModel.filter(x => x.propertyLandParcelGroupEventId === group.propertyLandParcelGroupEventId);
                    return landParcelDetail.length > 0 && (
                        <div className="p-0" key={group.propertyLandParcelGroupEventId}>
                            <Row className='ps-2'>
                                <Col lg={3} md={3} xs={3} className="fw-bold datasheet-land-parcel-group-header">
                                    {group.propertyLandParcelGroupEventDescription.toUpperCase()}
                                </Col>
                            </Row>
                            <table className='data-sheet-table table table-condensed table-hover mb-0'>
                                <thead className='table-header'>
                                    <tr>
                                        <th colSpan={1}>NO.</th>
                                        <th className="w-50" colSpan={1}>ASSOCIATED APN(S)</th>
                                        <th colSpan={1}>CLASSIFICATION</th>
                                        <th className='text-end' colSpan={1}>LAND AREA (SF)</th>
                                        <th className='text-end' colSpan={1}>LAND AREA (ACRES)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {landParcelDetail.map(detail => (
                                        <tr key={detail.sequenceNumber}>
                                            <td>{detail.sequenceNumber}</td>
                                            <td>{detail.assessorsParcelNumber}</td>
                                            <td>{detail.propertyLandParcelClassificationTypeDescription}</td>
                                            <td className='text-end'>{detail.landAreaSmallUOM !== null ? formatNumberWithDecimals(detail.landAreaSmallUOM, 0) : ''}</td>
                                            <td className='text-end'>{detail.landAreaLargeUOM !== null ? formatNumberWithDecimals(detail.landAreaLargeUOM, 4) : ''}</td>
                                        </tr>
                                    ))}
                                    <tr className="border-unset"><td className="border-unset"></td></tr>
                                    <tr className="border-unset"><td className="border-unset"></td></tr>
                                    <tr className="datasheet-land-parcel-footer">
                                        <td className="border-0" colSpan={2}></td>
                                        <td className="fw-bold border-0">Total Gross Land Area</td>
                                        {landParcelDetail.some(x => x.landAreaSmallUOM != null) ? (
                                            <>
                                                <td className="text-end fw-bold border-0">{formatNumberWithDecimals(landParcelDetail.reduce((sum, x) => sum + (x.landAreaSmallUOM || 0), 0), 0)}</td>
                                                <td className="text-end fw-bold border-0">{formatNumberWithDecimals(landParcelDetail.reduce((sum, x) => sum + (x.landAreaLargeUOM || 0), 0), 4)}</td>
                                            </>
                                        ) : (
                                            <td colSpan={2}></td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })}
            </Row >
            <Row className='p-2 mt-3 border'>
                <Row>
                    <Col md={9} sm={9} xs={9} className='total-width fw-bold'>Total Gross Land Area</Col>
                    {checkLandParcelLargeAreaTotalEmpty && checkLandParcelSmallAreaTotalEmpty &&
                        <>
                        <Col className="">
                            <p className='fw-bold text-end print-left'>{landParcelTotalModel.totalGrossLandAreaSmallUOM === 0 ?
                                    landParcelTotalModel.totalGrossLandAreaSmallUOM
                                    : formatNumberWithDecimals(landParcelTotalModel.totalGrossLandAreaSmallUOM, 0)}</p>
                            </Col>
                            <Col className='p-0'>
                                <p className='fw-bold text-end last-text'>{formatNumberWithDecimals(landParcelTotalModel.totalGrossLandAreaLargeUOM, 4)}</p>
                            </Col>
                        </>
                    }
                </Row>
                {isLandParcelUsableLineItemExist &&
                    <Row>
                        <Col md={9} sm={9} xs={9} className='fw-bold total-width'>Total Usable Land Area</Col>
                        {isLandParcelUsableLineItemWithVal &&
                            <><Col className="">
                                <p className='fw-bold text-end print-left'>{landParcelTotalModel.totalUsableLandAreaSmallUOM === 0 ?
                                    landParcelTotalModel.totalUsableLandAreaSmallUOM
                                    : formatNumberWithDecimals(landParcelTotalModel.totalUsableLandAreaSmallUOM, 0)}</p>
                            </Col>
                                <Col className='p-0'><p className='fw-bold text-end last-text'>{formatNumberWithDecimals(landParcelTotalModel.totalUsableLandAreaLargeUOM, 4)}</p></Col>
                            </>
                        }
                    </Row>
                }
                {isLandParcelSurplusLineItemExist &&
                    <Row>
                        <Col md={9} sm={9} xs={9} className='fw-bold total-width'>Total Surplus Land Area</Col>
                        {isLandParcelSurplusLineItemWithVal &&
                            <>
                            <Col className="">
                                <p className='fw-bold text-end print-left'>{landParcelTotalModel.totalSurplusLandAreaSmallUOM === 0 ?
                                        landParcelTotalModel.totalSurplusLandAreaSmallUOM
                                        : formatNumberWithDecimals(landParcelTotalModel.totalSurplusLandAreaSmallUOM, 0)}</p>
                                </Col>
                                <Col className='p-0'>
                                    <p className='fw-bold text-end last-text'>{formatNumberWithDecimals(landParcelTotalModel.totalSurplusLandAreaLargeUOM, 4)}</p>
                                </Col>
                            </>
                        }
                    </Row>
                }
                {isLandParcelExcessLineItemExist &&
                    <Row>
                        <Col md={9} sm={9} xs={9} className='fw-bold total-width'>Total Excess Land Area</Col>
                        {isLandParcelExcessLineItemWithVal &&
                            <>
                            <Col className="ps-left-90">
                                <p className='fw-bold text-end print-left'>{landParcelTotalModel.totalExcessLandAreaSmallUOM === 0 ?
                                        landParcelTotalModel.totalExcessLandAreaSmallUOM
                                        : formatNumberWithDecimals(landParcelTotalModel.totalExcessLandAreaSmallUOM, 0)}</p>
                                </Col>
                                <Col className='p-0'>
                                    <p className='fw-bold text-end last-text'>{formatNumberWithDecimals(landParcelTotalModel.totalExcessLandAreaLargeUOM, 4)}</p>
                                </Col>
                            </>
                        }
                    </Row>
                }
            </Row>
        </>);
    };

    const renderIndustrialBuildingLeasingDetails = () => {
        return (<>
            <Row>
                {(summaryModel.propertyTypeId === industrialPropertyType) && isVisibleIndustrialModel &&
                    <Col md={6} sm={6} xs={6}>
                        <div className="p-1 mt-3 heading-bg-primary">
                            <h5 className="title mb-1 fw-bold">Industrial Building Details</h5>
                        </div>
                    </Col>
                }
                {isVisibleImprovementLeaseModel &&
                    <Col className="p-0" md={6} sm={6} xs={6}>
                        <div className="p-1 mt-1 heading-bg-primary">
                            <h5 className="title ps-2 p-1 mb-1 fw-bold">Leasing Details</h5>
                        </div>
                    </Col>
                }
            </Row>
            <Row>
                {(summaryModel.propertyTypeId === industrialPropertyType) && isVisibleIndustrialModel &&
                    <Col md={6} sm={6} xs={6}>
                        {renderIndustrialBuildingDetails()}
                    </Col>
                }
                {isVisibleImprovementLeaseModel &&
                    <Col md={6} sm={6} xs={6}>
                        {renderLeasingDetails()}
                    </Col>
                }
            </Row>
        </>);
    };

    const renderIndustrialBuildingDetails = () => {
        return (<>
            {industrialModel.isVisiblePercentOffice &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Percent Office</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(industrialModel.percentOffice, 0)}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleNumberOfTruckDoors &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Drive In Doors</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.numberOfTruckDoors}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleDockHighDoors &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Dock High Doors</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>67</label> industrialModel.DockHighDoors
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleRailDoors &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Rail Doors</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.railDoors}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleTotalLoadingDoors &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Total Loading Doors</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.totalLoadingDoors}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleRatioOfSmallUOMPerLoadingDoor &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Ratio of SF per Loading Door</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.ratioOfSmallUOMPerLoadingDoor}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleClearHeight &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Clear Height</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.clearHeight}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleColumnSpacing &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Column Spacing</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.columnSpacing}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisiblePropertyCrossDock &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Cross Dock</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatYesNo(industrialModel.propertyCrossDock)}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleColdStorageSmallUOM &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Cold Storage SF</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>67</label> industrialModel.ColdStorageSmallUOM
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleColdStoragePercentage &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Cold Storage Percentage</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(industrialModel.coldStoragePercentage, 0)}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleCoolerSmallUOM &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Cooler SF</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{industrialModel.coolerSmallUOM}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleCoolerPercentage &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Cooler Percentage</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(industrialModel.coolerPercentage, 2)}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleFreeszerSmallUOM &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Freezer SF</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>67</label> industrialModel.FreeszerSmallUOM
                    </Col>
                </Row>
            }
            {industrialModel.isVisibleFreezerPercentage &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Freezer Percentage</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(industrialModel.freezerPercentage, 0)}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisiblePropertyRailAccess &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Rail Access</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatYesNo(industrialModel.propertyRailAccess)}</label>
                    </Col>
                </Row>
            }
            {industrialModel.isVisiblePercentLaboratory &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Percent Laboratory</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(industrialModel.percentLaboratory, 2)}</label>
                    </Col>
                </Row>
            }
        </>);
    };

    const renderLeasingDetails = () => {
        return (<>
            {improvementLeaseModel.isVisiblePropertyOwnerTenantStatusTypeDescription &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Owner Tenant Status</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{improvementLeaseModel.propertyOwnerTenantStatusTypeDescription}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleAverageRemainingLeaseTerm &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Average Remaining Lease Term</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimals(improvementLeaseModel.averageRemainingLeaseTerm, 2)}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisiblePropertyOccupantTypeDescription &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Occupant Type</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{improvementLeaseModel.propertyOccupantTypeDescription}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisiblePropertyOccupancyTypeDescription &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Occupancy Type</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{improvementLeaseModel.propertyOccupancyTypeDescription}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleOwnerOccupiedPercent &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Owner Occupied Percent</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(improvementLeaseModel.ownerOccupiedPercent, 2)}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleNumberOfTenants &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Number Of Tenants</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{improvementLeaseModel.numberOfTenants}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleTenantSizeRange &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Tenant Size Range</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{improvementLeaseModel.tenantSizeRange}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleRentControlled &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Rent Controlled</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatYesNo(improvementLeaseModel.rentControlled)}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleRentSubsidized &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Rent Subsidized</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{formatYesNo(improvementLeaseModel.rentSubsidized)}</label>
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisiblePropertyTenantPaidUtilityTypes &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Tenant Paid Utilities</label>
                    </Col>
                    <Col xs={6}>
                        {getTenantPaidUtilities().map((value, index) => (
                            <span className='fw-normal'>• {value} <br /></span>))}
                    </Col>
                </Row>
            }
            {improvementLeaseModel.isVisibleTenantPaidUtilities &&
                <Row>
                    <Col xs={5}>
                        <label className='light-text'>Additional Tenant Paid Utilities</label>
                    </Col>
                    <Col xs={6}>
                        <label className='fw-normal'>{improvementLeaseModel.tenantPaidUtilities}</label>
                    </Col>
                </Row>
            }
        </>);
    };

    const renderUnitMixes = () => {
        return (<>
            <Row>
                <div className="p-1 mt-1 heading-bg-primary">
                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Unit Mix</h5>
                </div>
            </Row>
            <Row>
                <div className='d-inline p-0 mt-1'>
                    {!isSelfStoragePropertySubtype &&
                        <label className='fw-bold ps-2'>PHYSICAL</label>
                    }
                    <Row className='d-inline p-0 mt-1'>
                        <table className='data-sheet-table table table-condensed table-hover mb-0 border'>
                            <thead className="table-header">
                                <tr>
                                    <th>UNIT DESCRIPTION</th>
                                    {isAffordableHousing && !isSelfStoragePropertySubtype &&
                                        <th className='text-end'>% AMI</th>
                                    }
                                    {!isSelfStoragePropertySubtype &&
                                        <>
                                            <th className='text-end'>BR</th>
                                            <th className='text-end'>BA</th>
                                        </>
                                    }
                                    <th className='text-end'>UNITS</th>
                                    <th className='text-end'>% OF TOTAL UNITS</th>
                                    <th className='text-end'>UNIT SF</th>
                                    <th className='text-end'>TOTAL SF</th>
                                    {isSelfStoragePropertySubtype &&
                                        <th className='text-end'>% OF TOTAL SF</th>
                                    }
                                    <th className='text-end'>VACANT UNITS</th>
                                    {isSelfStoragePropertySubtype &&
                                        <>
                                            <th className='text-end'>ASKING RENT</th>
                                            <th className='text-end'>ASKING RENT PER SF</th>
                                            <th className='text-end'>TOTAL ASKING RENT</th>
                                        </>
                                    }
                                    {isAffordableHousing && !isSelfStoragePropertySubtype &&
                                        <th className='text-end'>UTILITY ALLOWANCE</th>
                                    }
                                    <th>COMMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {unitMixModels.map(umModel => (
                                    <tr>
                                        <td>{umModel.unitType}</td>
                                        {isAffordableHousing && !isSelfStoragePropertySubtype &&
                                            <td className='text-end'>{umModel.propertyUnitMixPercentAMITypeDescription}</td>
                                        }
                                        {!isSelfStoragePropertySubtype &&
                                            <>
                                                <td className='text-end'>{formatNumberWithDecimals(umModel.numberOfBedrooms, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimals(umModel.numberOfBathrooms, 2)}</td>
                                            </>
                                        }
                                        <td className='text-end'> {formatNumberWithDecimals(umModel.numberOfUnits, 2)}</td>
                                        <td className='text-end'> {formatNumberWithDecimalsWithPercentage(umModel.percentOfTotal, 0)}</td>
                                        <td className='text-end'> {formatNumberWithDecimals(umModel.approximateUnitsSmallUOM, 2)}</td>
                                        <td className='text-end'> {formatNumberWithDecimals(umModel.totalUnitsSmallUOM, 2)}</td>
                                        {isSelfStoragePropertySubtype &&
                                            <td className='text-end'>{formatNumberWithDecimalsWithPercentage(umModel.percentOfTotalUnitsSmallUOM, 0)}</td>
                                        }
                                        <td className='text-end'> {formatNumberWithDecimals(umModel.vacantUnits, 2)}</td>
                                        {isSelfStoragePropertySubtype &&
                                            <>
                                                <td className='text-end'> {formatNumberWithDecimalsWithCurrency(umModel.askingRent, currencySymbolCode, 2)}</td>
                                                <td className='text-end'> {formatNumberWithDecimalsWithCurrency(umModel.effectiveValuePerSmallUOM, currencySymbolCode, 2)}</td>
                                                <td className='text-end'> {formatNumberWithDecimalsWithCurrency(umModel.totalRent, currencySymbolCode, 2)}</td>
                                            </>
                                        }
                                        {isAffordableHousing && !isSelfStoragePropertySubtype &&
                                            <td className='text-end'> {formatNumberWithDecimalsWithCurrency(umModel.utilityAllowance, currencySymbolCode, 2)}</td>
                                        }
                                        <td>{umModel.unitComments}</td>
                                    </tr>
                                ))}
                                <tr className='fw-bold'>
                                    <td>Total/Average</td>
                                    {!isSelfStoragePropertySubtype &&
                                        <>
                                            <td></td>
                                            <td></td>
                                        </>
                                    }
                                    {isAffordableHousing && !isSelfStoragePropertySubtype &&
                                        <td></td>
                                    }
                                    <td className='text-end'>{formatNumberWithDecimals(unitMixTotal.numberOfUnits, 2)}</td>
                                    <td className='text-end'>{formatNumberWithDecimalsWithPercentage(unitMixTotal.percentOfTotal, 0)}</td>
                                    <td className='text-end'>{formatNumberWithDecimals(unitMixTotal.approximateUnitsSmallUOM, 2)}</td>
                                    <td className='text-end'>{formatNumberWithDecimals(unitMixTotal.totalUnitsSmallUOM, 2)}</td>
                                    {isSelfStoragePropertySubtype &&
                                        <td className='text-end'>{formatNumberWithDecimalsWithPercentage(unitMixTotal.percentOfTotalUnitsSmallUOM, 0)}</td>
                                    }
                                    <td className='text-end'>{formatNumberWithDecimals(unitMixTotal.vacantUnits, 2)}</td>
                                    {isSelfStoragePropertySubtype &&
                                        <>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.askingRent, currencySymbolCode, 2)}</td>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.effectiveValuePerSmallUOM, currencySymbolCode, 2)}</td>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.totalRent, currencySymbolCode, 2)}</td>
                                        </>
                                    }
                                    {isAffordableHousing && !isSelfStoragePropertySubtype &&
                                        <>
                                            <td></td>
                                            <td></td>
                                        </>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    {!isSelfStoragePropertySubtype &&
                        <Row className='d-inline p-0 mt-1'>
                            <Row>
                                <label className='fw-bold'>ECONOMIC</label>
                            </Row>
                            <Row className='d-inline p-0 '>
                                <table className='data-sheet-table table table-condensed table-hover mb-0 border'>
                                    <thead className="table-header">
                                        <tr>
                                            <th className='border-0'></th>
                                            <th className='border-0'></th>
                                            {isAffordableHousing &&
                                                <th className='border-0'></th>
                                            }
                                            <th colSpan={3} className='border-0'>CONTRACT RENT</th>
                                        </tr>
                                        <tr>
                                            <th>UNIT DESCRIPTION</th>
                                            {isAffordableHousing &&
                                                <th className='text-end'>%AMI</th>
                                            }
                                            <th className='text-end'>LOW</th>
                                            <th className='text-end'>HIGH</th>
                                            <th className='text-end'>AVG</th>
                                            <th className='text-end'>ASKING RENT</th>
                                            <th className='text-end'>ASKING RENT PER SF</th>
                                            <th className='text-end'>TOTAL ASKING RENT</th>
                                            {isAffordableHousing &&
                                                <>
                                                    <th className='text-end'>UTILITY ALLOWANCE</th>
                                                    <th className='text-end'>GROSS RENT</th>
                                                    <th className='text-end'>TOTAL GROSS RENT</th>
                                                    <th className='text-end'>GROSS RENT PER SF</th>
                                                </>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {unitMixModels.map(umModel => (
                                            <tr>
                                                <td>{getEconomicGridDescription(umModel)}</td>
                                                {isAffordableHousing &&
                                                    <td className='text-end'>{umModel.propertyUnitMixPercentAMITypeDescription}</td>
                                                }
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.lowContractRent, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.highContractRent, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.avgContractRent, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.askingRent, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.effectiveValuePerSmallUOM, currencySymbolCode, 2)}</td>
                                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.totalRent, currencySymbolCode, 2)}</td>
                                                {isAffordableHousing &&
                                                    <>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.utilityAllowance, currencySymbolCode, 2)}</td>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.grossRent, currencySymbolCode, 2)}</td>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.totalGrossRent, currencySymbolCode, 2)}</td>
                                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(umModel.grossRentPerSmallUOM, currencySymbolCode, 2)}</td>
                                                    </>
                                                }
                                            </tr>
                                        ))}
                                        <tr className='fw-bold'>
                                            <td>Total/Average</td>
                                            {isAffordableHousing &&
                                                <td></td>
                                            }
                                            <td></td>
                                            <td></td>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.avgContractRent, currencySymbolCode, 2)}</td>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.askingRent, currencySymbolCode, 2)}</td>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.effectiveValuePerSmallUOM, currencySymbolCode, 2)}</td>
                                            <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.totalRent, currencySymbolCode, 2)}</td>
                                            {isAffordableHousing &&
                                                <>
                                                    <td></td>
                                                    <td></td>
                                                    <td className='text-end'>{formatNumberWithDecimalsWithCurrency(unitMixTotal.totalGrossRent, currencySymbolCode, 2)}</td>
                                                    <td></td>
                                                </>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>
                        </Row>
                    }
                </div>
            </Row>
        </>);
    };

    const renderCommercialSpaces = () => {
        return (
            <>
                <Row>
                    <div className="p-1 mt-1 heading-bg-primary">
                        <h5 className="title ps-2 p-1 mb-1 fw-bold">Commercial Space Summary</h5>
                    </div>
                </Row>
                <Row>
                    <table>
                        <thead className="table-header">
                            <tr>
                                <th className='ps-2'> SPACE TYPE</th>
                                <th className='ps-2'> SAMPLE IN-PLACE TENANTS</th>
                                <th className='text-end ps-2'> TOTAL SF</th>
                                <th className='text-end ps-2'> % TOTAL</th>
                                <th className='text-end ps-2'> LEASED SF </th>
                                <th className='text-end ps-2'> OCCUPANCY </th>
                                <th className='text-end ps-2'> AVG ASKING RENT PER SF </th>
                                <th className='text-end ps-2'> AVG CONTRACT RENT PER SF </th>
                                <th className='text-start ps-2'> LEASE REIMBURSEMENT METHOD</th>
                                <th className='text-end ps-2'> AVG REMAINING LEASE TERM (MOS)</th>
                                <th className='text-start ps-2'> COMMENTS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commercialModels.map(comModel =>
                                <tr className='border-bottom'>
                                    <td className='ps-2'>{comModel.propertyCommercialSpaceTypeDescription}</td>
                                    <td className='ps-2'>{comModel.tenantName}</td>
                                    <td className='text-end ps-2'>{formatNumberWithNoDecimals(comModel.gla)}</td>
                                    <td className='text-end ps-2'>{formatNumberWithDecimalsWithPercentage(comModel.percentageTotal, 2)}</td>
                                    <td className='text-end ps-2'>{comModel.leasedSmallUOM}</td>
                                    <td className='text-end ps-2'>{formatNumberWithDecimalsWithPercentage(comModel.occupancyPercent, 2)}</td>
                                    <td className='text-end ps-2'>{formatNumberWithDecimalsWithCurrency(comModel.avgAskingRentPerSmallUOM, currencySymbolCode, 2)}</td>
                                    <td className='text-end ps-2'>{formatNumberWithDecimalsWithCurrency(comModel.avgContractRentPerSmallUOM, currencySymbolCode, 2)}</td>
                                    <td className='text-start ps-2'>{comModel.propertyLeaseReimbursementMethodTypeDescription}</td>
                                    <td className='text-end ps-2'>{formatNumberWithDecimals(comModel.avgRemainingLeaseTerm, 0)}</td>
                                    <td className='text-start ps-2 text-break'>{comModel.comments}</td>
                                </tr>
                            )}
                            <tr className='fw-bold'>
                                <td className='ps-2'>SubTotal</td>
                                <td></td>
                                {commercialSpaceTotal &&
                                    <>
                                        <td className='text-end'>{formatNumberWithNoDecimals(commercialSpaceTotal.totalRentableAreaSmallUOM)}</td>
                                        <td className='text-end'>{formatNumberWithDecimalsWithPercentage(commercialSpaceTotal.totalPercentage, 2)}</td>
                                        <td className='text-end'>{formatNumberWithNoDecimals(commercialSpaceTotal.totalLeasedSmallUOM)}</td>
                                        <td className='text-end'>{formatNumberWithDecimalsWithPercentage(commercialSpaceTotal.totalOccupancyPercent, 2)}</td>
                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(commercialSpaceTotal.totalAvgAskingRentPerSmallUOM, currencySymbolCode, 2)}</td>
                                        <td className='text-end'>{formatNumberWithDecimalsWithCurrency(commercialSpaceTotal.totalAvgContractRentPerSmallUOM, currencySymbolCode, 2)}</td>
                                        <td className='text-end'></td>
                                        <td className='text-end'>{formatNumberWithDecimals(commercialSpaceTotal.totalAvgRemainingLeaseTerm, 1)}</td>
                                    </>
                                }
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </Row>
            </>
        );
    };

    return (
        <>
            {isSummaryModelVisible &&
                renderLocationProperty()
            }
            {taxAssessmentConfigurationModel != null && taxAssessmentModel != null && taxAssessmentModel.length > 0 &&
                renderTaxAssessments()
            }
            {landParcelGroupModel != null && landParcelGroupModel.length > 0 &&
                renderLandParcel()
            }
            {isSiteModelVisible &&
                renderSiteDetails()
            }
            {isImprovementModelVisible &&
                renderImprovementDetails()
            }
            {isVisibleRentalSurveyModel &&
                renderLeaseAvailabilityInfo()
            }
            {isVisibleImprovementLeaseModel &&
                renderIndustrialBuildingLeasingDetails()
            }
            {unitMixModels != null && unitMixModels.length > 0 &&
                renderUnitMixes()
            }
            {commercialModels != null && commercialModels.length > 0 &&
                renderCommercialSpaces()
            }
        </>
    );
};
export default PropertyEventShared;

