import { ReportRentalSurveyEventModel } from 'dto/reports/reportRentalSurveyEvent';
import PropertyEventShared from './propertyEventShared';
import PropertyAndEventComments from './propertyEventComments';
import { applyRentalConfidential, setRentalSurveyEventModelVisibility } from 'components/utils/reports/reportVisibility';
import { ReportPropertyLeaseDetailEventModel } from 'dto/reports/shared/reportPropertyLeaseDetailEvent';
import { Col, Row } from 'reactstrap';
import { formatNumberWithDecimals, formatNumberWithDecimalsWithCurrency, formatNumberWithDecimalsWithPercentage, formatNumberWithNoDecimals, formatYesNo } from 'components/utils/numberFormatting/numberFormat';
import { dateFormatter } from 'components/utils/columnFormatting/date';

const RentalSurvey = ({ data }: { data: ReportRentalSurveyEventModel }) => {
    let rentalEventModel = data as ReportRentalSurveyEventModel;
    let rentalEvent: ReportRentalSurveyEventModel;
    let rentalEventVisibleModel = setRentalSurveyEventModelVisibility(rentalEventModel);
    if (rentalEventVisibleModel?.reportPropertyEventModel?.isConfidential) {
        rentalEvent = applyRentalConfidential(rentalEventVisibleModel);
    }
    else
        rentalEvent = rentalEventVisibleModel;
    let reportPropertyEventModel = rentalEvent?.reportPropertyEventModel;
    let leaseDetailModels: ReportPropertyLeaseDetailEventModel[] = rentalEvent.reportPropertyLeaseDetailEventModels;
    const currencyTypeCode: string = reportPropertyEventModel ? reportPropertyEventModel.currencyTypeCode : 'USD';

    function getRentalTitle(): string {
        const title = 'Lease Summary';
        let propertyType = reportPropertyEventModel?.propertyTypeDescription || '';
        if (propertyType) {
            return propertyType + ' ' + title;
        }
        return title;
    }
    const sortBySequenceNumber = (a: ReportPropertyLeaseDetailEventModel, b: ReportPropertyLeaseDetailEventModel) => {
        if (a.sequenceNumber === null && b.sequenceNumber === null)
            return 0;
        if (a.sequenceNumber === null)
            return -1;
        if (b.sequenceNumber === null)
            return 1;
        return a.sequenceNumber - b.sequenceNumber;
    }
    function ratingDate(dateAsString: string | null): string {
        if (dateAsString) {
            const options: Intl.DateTimeFormatOptions = {
                month: "2-digit",
                year: "numeric",
            };
            return new Date(dateAsString).toLocaleDateString(undefined, options);
        }
        return '';
    }

    const hasDescriptionOfPremises = (leaseDetail: ReportPropertyLeaseDetailEventModel): boolean => {
        return (
            leaseDetail.isVisibleRentableArea ||
            leaseDetail.isVisibleFullBuildingLease ||
            leaseDetail.isVisibleSuiteSpaceNumber ||
            leaseDetail.isVisibleNumberParkingSpaces ||
            leaseDetail.isVisiblePropertySpaceTypeDescription ||
            leaseDetail.isVisiblePropertyCommercialSpaceTypeDescription ||
            leaseDetail.isVisibleAlternateArea
        );
    };

    const hasLeaseDetails = (leaseDetail: ReportPropertyLeaseDetailEventModel): boolean => {
        return (
            leaseDetail.isVisiblePropertyLeaseStatusTypeDescription ||
            leaseDetail.isVisibleLeaseSignedDate ||
            leaseDetail.isVisibleLeaseStartAvailableDate ||
            leaseDetail.isVisibleLeaseExpirationDate ||
            leaseDetail.isVisibleLeaseTerm ||
            leaseDetail.isVisibleLessor ||
            leaseDetail.isVisibleLessee ||
            leaseDetail.isVisiblePropertyLesseeTypeDescription ||
            leaseDetail.isVisibleTenantCreditRating ||
            leaseDetail.isVisiblePropertyCreditRatingSourceTypeDescription ||
            leaseDetail.isVisibleRatingDate
        );
    };

    const hasIndustrialSpaceDetails = (leaseDetail: ReportPropertyLeaseDetailEventModel): boolean => {
        return (
            leaseDetail.isVisibleTypicalCeilingHeight ||
            leaseDetail.isVisibleOfficePercentage ||
            leaseDetail.isVisibleHVACPercent ||
            leaseDetail.isVisibleNumberLoadingDoors
        );
    };

    const hasRatesMeasures = (leaseDetail: ReportPropertyLeaseDetailEventModel): boolean => {
        return (
            leaseDetail.isVisiblePropertyMeasureTypeDescription ||
            leaseDetail.isVisibleEffectiveRentalRate ||
            leaseDetail.isVisiblePropertyBaseRentEscalationTypeDescription ||
            leaseDetail.isVisibleEscalationDescription ||
            leaseDetail.isVisiblePercentageRent ||
            leaseDetail.isVisibleLeaseReimbursementDescription ||
            leaseDetail.isVisiblePropertyLeaseReimbursementMethodTypeDescription ||
            leaseDetail.isVisibleExpenseStopDescription ||
            leaseDetail.isVisiblePropertyLandlordExpenseStop ||
            leaseDetail.isVisibleTenantPaidExpenses ||
            leaseDetail.isVisibleExpenseCapDescription ||
            leaseDetail.isVisibleEstimatedAnnnualPropertyExpensePerSmallUOM ||
            leaseDetail.isVisibleLandlordPaidExpenses ||
            leaseDetail.isVisibleRenewalOptionsIncluded ||
            leaseDetail.isVisibleRenewalOptionsDescription ||
            leaseDetail.isVisibleConcessions ||
            leaseDetail.isVisibleFreeRentMonth ||
            leaseDetail.isVisibleTenantImprovementAllowancePerSmallUOM ||
            leaseDetail.isVisibleFaceRentalRate ||
            leaseDetail.isVisiblePropertyTenantImprovementTypeId
        );
    };

    const renderLeaseSummary = () => {
        return (<>
            <Row>
                <div className="d-inline p-1 mt-3 heading-bg-primary">
                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Lease Summary</h5>
                </div>
            </Row>
            <Row>
                <table className='table-header'>
                    <thead>
                        <tr className='border-bottom'>
                            <th className='text-start ps-2'> START DATE</th>
                            <th className='text-end pe-2'> TERM</th>
                            <th className='text-start pe-2'> SPACE TYPE</th>
                            <th className='text-start pe-2'> LESSEE</th>
                            <th className='text-start pe-2'> LESSEE TYPE</th>
                            <th className='text-end pe-2'> LEASE SF</th>
                            <th className='text-end pe-2'> ALTERNATE SF</th>
                            <th className='text-end pe-2'> EFF. RATE</th>
                            <th className='text-start ps-4'> RENT MEASURE</th>
                            <th className='text-start pe-2'> BASE ESC. TYPE</th>
                            <th className='text-start pe-2'> REIMB. METHOD</th>
                            <th className='text-end pe-2'> TI $/SF</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaseDetailModels && Array.isArray(leaseDetailModels) && leaseDetailModels.sort(sortBySequenceNumber).map(leaseDetail => (
                            <tr className='border-bottom'>
                                <td className='text-start ps-2'>{dateFormatter(leaseDetail.leaseStartAvailableDate)}</td>
                                <td className='text-end pe-2'>{formatNumberWithDecimals(leaseDetail.leaseTerm, 0)}</td>
                                <td className='text-start'>{leaseDetail.propertyCommercialSpaceTypeDescription}</td>
                                <td className='text-start'>{leaseDetail.lessee}</td>
                                <td className='text-start'>{leaseDetail.propertyLesseeTypeDescription}</td>
                                <td className='text-end'>{formatNumberWithDecimals(leaseDetail.rentableArea, 0)}</td>
                                <td className='text-end'>{formatNumberWithDecimals(leaseDetail.alternateArea, 0)}</td>
                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(leaseDetail.effectiveRentalRate, currencyTypeCode, 2)}</td>
                                <td className='text-start ps-4'>{leaseDetail.propertyMeasureTypeDescription}</td>
                                <td className='text-start'>{leaseDetail.propertyBaseRentEscalationTypeDescription}</td>
                                <td className='text-start'>{leaseDetail.propertyLeaseReimbursementMethodTypeDescription}</td>
                                <td className='text-end'>{formatNumberWithDecimalsWithCurrency(leaseDetail.tenantImprovementAllowancePerSmallUOM, currencyTypeCode, 2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Row>
        </>);
    }
    const renderLeaseDetails = () => {

        const getSpecificLeaseDetailsHeader = (leaseDetail: ReportPropertyLeaseDetailEventModel): string => {
            let specificLeaseDetailsHeaderText = 'Specific Lease Details';
            let specificLeaseDetailsHeaderBasedOnLesseeText = leaseDetail.isVisibleLessee ?
                specificLeaseDetailsHeaderText + ': ' + leaseDetail?.lessee :
                (leaseDetail.isVisiblePropertyLeaseStatusTypeDescription ?
                    specificLeaseDetailsHeaderText + ': ' + leaseDetail?.propertyLeaseStatusTypeDescription :
                    specificLeaseDetailsHeaderText
                );
            return specificLeaseDetailsHeaderBasedOnLesseeText;
        };
        return (<>
            <Row>
                {leaseDetailModels && Array.isArray(leaseDetailModels) && leaseDetailModels.sort(sortBySequenceNumber).map(leaseDetail => (

                    <>
                        <div className="d-inline p-1 mt-3 heading-bg-primary">
                            <h5 className="title ps-2 p-1 mb-1 fw-bold">{getSpecificLeaseDetailsHeader(leaseDetail)}</h5>
                        </div>
                        <Row>
                            <Col md={6} sm={6} xs={6}>
                                {hasDescriptionOfPremises(leaseDetail) || hasIndustrialSpaceDetails(leaseDetail) ?
                                    (<>
                                        {hasDescriptionOfPremises(leaseDetail) &&
                                            <>
                                                <Row>
                                                    <Col className="d-inline p-1 mt-1 heading-bg-primary">
                                                    <h5 className="title ps-2 p-1mb-1 fw-bold">Description of Premises</h5>
                                                    </Col>
                                                </Row>
                                                {leaseDetail.isVisibleRentableArea &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Rentable Area</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimals(leaseDetail.rentableArea, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleAlternateArea &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Alternate SF</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimals(leaseDetail.alternateArea, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            {leaseDetail.isVisibleFullBuildingLease &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Full Building Lease</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatYesNo(leaseDetail.fullBuildingLease)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyCommercialSpaceTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Space Type</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyCommercialSpaceTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleSuiteSpaceNumber &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Suite/Space Number</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.suiteSpaceNumber}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleNumberParkingSpaces &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>No. Parking Spaces</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimals(leaseDetail.numberParkingSpaces, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        }
                                        {hasIndustrialSpaceDetails(leaseDetail) &&
                                            <>
                                                <Row>
                                                <Col className="d-inline p-1 mt-3 heading-bg-primary">
                                                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Industrial Space Details</h5>
                                                    </Col>
                                                </Row>
                                                {leaseDetail.isVisibleTypicalCeilingHeight &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Typical Ceiling Height</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimals(leaseDetail.typicalCeilingHeight, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleOfficePercentage &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Percentage Office</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(leaseDetail.officePercentage, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleHVACPercent &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Percent AC</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{formatNumberWithDecimalsWithPercentage(leaseDetail.hvacPercent, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleNumberLoadingDoors &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>No. Loading Doors</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimals(leaseDetail.numberLoadingDoors, 0)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        }
                                    </>)
                                    : <></>
                                }
                                {hasLeaseDetails(leaseDetail) || hasRatesMeasures(leaseDetail) ?
                                    (<>
                                        {hasLeaseDetails(leaseDetail) &&
                                            <>
                                                <Row>
                                                <Col className="d-inline p-1 mt31 heading-bg-primary">
                                                    <h5 className="title ps-2 p-1 mb-1 fw-bold">Lease Details</h5>
                                                    </Col>
                                                </Row>
                                                {leaseDetail.isVisiblePropertyLeaseStatusTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lease Status</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyLeaseStatusTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLeaseSignedDate &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lease Signed Date</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{dateFormatter(leaseDetail.leaseSignedDate)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLeaseStartAvailableDate &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lease Start/Available Date</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{dateFormatter(leaseDetail.leaseStartAvailableDate)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLeaseExpirationDate &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lease Expiration Date</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{dateFormatter(leaseDetail.leaseExpirationDate)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLeaseTerm &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Term of Lease (Months)</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithNoDecimals(leaseDetail.leaseTerm)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLessor &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lessor</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.lessor}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLessee &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lessee</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.lessee}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyLesseeTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lessee Type</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyLesseeTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleTenantCreditRating &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Tenant Credit Rating</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.tenantCreditRating}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyCreditRatingSourceTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Credit Rating Source</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyCreditRatingSourceTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleRatingDate &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Rating Date</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{ratingDate(leaseDetail.ratingDate)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        }
                                        {hasRatesMeasures(leaseDetail) &&
                                            <>
                                                <Row>
                                                <Col className="d-inline p-1 mt-3 heading-bg-primary">
                                                    <h5 className="title mb-1 fw-bold ps-2 p-1 ">Rates & Measures</h5>
                                                    </Col>
                                                </Row>
                                                {leaseDetail.isVisiblePropertyMeasureTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Measure</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyMeasureTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleFaceRentalRate &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Face Rental Rate</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimalsWithCurrency(leaseDetail.faceRentalRate, currencyTypeCode, 2)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleEffectiveRentalRate &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Effective Rental Rate</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithDecimalsWithCurrency(leaseDetail.effectiveRentalRate, currencyTypeCode, 2)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyBaseRentEscalationTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Base Rent Escalation Type</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyBaseRentEscalationTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleEscalationDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Escalation Description</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.escalationDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePercentageRent &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Percentage Rent</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.percentageRent}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyLeaseReimbursementMethodTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lease Reimbursement Method</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyLeaseReimbursementMethodTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLeaseReimbursementDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Lease Reimbursement Description</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.leaseReimbursementDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleExpenseStopDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Expense Stop Description</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.expenseStopDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            {leaseDetail.isVisiblePropertyLandlordExpenseStop &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Landlord Expense Stop</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{formatYesNo(leaseDetail.propertyLandlordExpenseStop)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyTenantExpenseCap &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Tenant Expense Cap</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <label className='fw-normal'>{formatYesNo(leaseDetail.propertyTenantExpenseCap)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleExpenseCapDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Expense Cap Description</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.expenseCapDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleEstimatedAnnnualPropertyExpensePerSmallUOM &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Est. Annual Property Exp. PSF</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>
                                                                {formatNumberWithDecimalsWithCurrency(leaseDetail.estimatedAnnnualPropertyExpensePerSmallUOM, currencyTypeCode, 2)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleLandlordPaidExpenses &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Landlord Paid Expenses</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.landlordPaidExpenses}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleTenantPaidExpenses &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Tenant Paid Expenses</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.tenantPaidExpenses}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            {leaseDetail.isVisibleRenewalOptionsIncluded &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Renewal Options Included?</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatYesNo(leaseDetail.renewalOptionsIncluded)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleRenewalOptionsDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Renewal Options Description</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.renewalOptionsDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleConcessions &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Concessions</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.concessions}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleFreeRentMonth &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Free Rent (Months)</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{formatNumberWithNoDecimals(leaseDetail.freeRentMonth)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisibleTenantImprovementAllowancePerSmallUOM &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>TI Allowance/SF</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>
                                                                {formatNumberWithDecimalsWithCurrency(leaseDetail.tenantImprovementAllowancePerSmallUOM, currencyTypeCode, 2)}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                                {leaseDetail.isVisiblePropertyTenantImprovementTypeDescription &&
                                                    <Row>
                                                        <Col xs={5}>
                                                            <label className='light-text'>Tenant Improvement Type</label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <label className='fw-normal'>{leaseDetail.propertyTenantImprovementTypeDescription}</label>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                        }
                                    </>)
                                    : <></>
                                }
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                                {leaseDetail.urlImageCompressed &&

                                    <div className="card bg-white border-0 mt-1">
                                        <img className="card-img-top rounded-0" src={leaseDetail.urlImageCompressed} alt="Card image cap" />

                                    </div>
                                }
                            </Col>
                        </Row>
                        {leaseDetail.isVisibleComments &&
                            <>
                                <div className="d-inline p-1 mt-1 heading-bg-primary">
                                <h5 className="title mb-1 fw-bold ps-2 p-1">Lease Notes</h5>
                                </div>
                                <Row>
                                    <p>{leaseDetail.comments}</p>
                                </Row>
                            </>
                        }
                    </>
                ))}

            </Row>
        </>);
    };
    return (
        <div style={{ position: "relative" }}>
            <div className='watermark Rent-Watermark'></div>
            <div className='col-md-12 col-print-12 p-5 shadow-sm bg-white border mb-5'>
                <div className='row'>
                    <div className='d-inline col-6 p-0 mb-3'>
                        <h2 className='fw-bold'>{getRentalTitle()}</h2> 
                    </div>
                    <div className='d-flex col-6 p-0 justify-content-end'>
                        <span className='logo-NKF-report'></span>
                    </div>
                </div>
                <div className='row'>
                    <div className="d-inline p-1 heading-bg-primary">
                        <h2>{reportPropertyEventModel?.address1}</h2>
                        <h6>{reportPropertyEventModel?.name}</h6> 
                    </div>
                </div>
                {rentalEvent.staticMapImageUrl && !reportPropertyEventModel?.isConfidential &&
                <div className=''>
                    <div className='row mt-1'>
                            <img className="p-0 img-fluid" src={rentalEvent.staticMapImageUrl} alt='Map' />
                    </div>
                </div>
                }
                {rentalEvent &&
                <PropertyEventShared rental={rentalEvent} compTypeId={2} sale={null} incomeExpense={null} />
                }
                {leaseDetailModels && leaseDetailModels.length > 0 &&
                    renderLeaseSummary()
                }
                {(reportPropertyEventModel?.isVisibleComments || reportPropertyEventModel?.isVisibleEventComments) &&
                <PropertyAndEventComments data={reportPropertyEventModel} />
                }
                {leaseDetailModels && leaseDetailModels.length > 0 &&
                    renderLeaseDetails()
                }                
            </div>
        </div>
    );
};
export default RentalSurvey;