export function formatNumberWithDecimalsWithCurrency(value: number | null, currencySymbolCode: string, decimals: number): string {
    if (value !== null) {
        return new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencySymbolCode,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }).format(value);
    }
    return "";
}

export function formatNumberNoDecimalsWithCurrency(value: number | null, currencySymbolCode: string): string {
    if (value !== null) {
        return new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: currencySymbolCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    }
    return "";
}

export function formatNumberWithDecimals(value: number | null, decimals: number): string {
    if (value !== null) {
        return new Intl.NumberFormat(undefined, {           
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals }).format(value);
    }
    return "";    
}

export function formatNumberWithDecimalsWithPercentage(value: number | null, decimals: number): string {
    if (value !== null) {
        return new Intl.NumberFormat(undefined, {
            style: 'percent',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }).format(value);
    }
    return "";
}

export function formatValueWithDecimal(value: number | null): string {
    if (value !== undefined && value !== null) {
        return value % 1 === 0 ? new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(value)
            : new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }
    return "0";
}

export function formatNumberWithNoDecimals(value: number | null): string {
    if (value !== null) {
        return new Intl.NumberFormat(undefined, {           
            minimumFractionDigits: 0,
            maximumFractionDigits: 0 }).format(value);
    }
    return "";    
}

export function formatYesNo(value: boolean | null | undefined): string { 
    if (value === true) 
        return 'Yes'; 
    if (value === false) 
        return 'No'; 
    return ''; 
};

export function displayPatternValue(pattern: string, value: number | null, currencyType: string): string{ 
    if(value === null || value === undefined)
        return '';
    const match = pattern.match(/{0:\s*([cCnNpP])(\d)}/i);
    if (!match) 
        return value.toString();
    const [, typeRaw, precision] = match; 
    const type = typeRaw.toLowerCase();
    const decimals = parseInt(precision, 10);

    switch (type) { 
        case 'c':
        let currencyCode =  currencyType ? currencyType : 'USD';   
         return new Intl.NumberFormat(undefined, { 
            style: 'currency', 
            currency: currencyCode, 
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals, }).format(value);
        case 'p': return new Intl.NumberFormat(undefined, { 
            style: 'percent', 
            minimumFractionDigits: decimals, 
            maximumFractionDigits: decimals}).format(value); 
        case 'n':return new Intl.NumberFormat(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals, }).format(value);
        default: return value.toString(); 
    } 
};